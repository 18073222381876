import HttpProvider from "@/providers/HttpProvider"
import { AxiosResponse } from "axios"
import { OldFilterDTO } from "../application/OldFilterDTO"
import { SortDTO } from "../application/SortDTO"
import { UrlHelper } from "../application/UrlHelper"
import EmbalagemDTO from "./EmbalagemDTO"

class EmbalagemAPI {

    private httpProvider: HttpProvider;

    constructor() {
        this.httpProvider = new HttpProvider()
    }

    public buscarEmbalagens(skip = 0, take = 100, sort: SortDTO, filters: OldFilterDTO): Promise<AxiosResponse<Array<EmbalagemDTO>>> {
        const encodedFilter = UrlHelper.buildFilterWithSortAndSkipTake(skip, take, sort, filters)
        return this.httpProvider.axiosInstanceBase.get(`/embalagem/v2?${encodedFilter}`)
    }

}


export default EmbalagemAPI
