import axios, { AxiosInstance } from "axios"
import LocalStorageProvider, { LocalStorageEnum } from "@/providers/LocalStorageProviders"
import ConfigDTO from "@/domains/application/ConfigDTO"

export default class HttpProvider {
    public axiosInstanceBase: AxiosInstance
    public axiosInstancePadraoDeFrete: AxiosInstance
    public axiosInstanceDashboard: AxiosInstance
    public axiosInstanceCotacao: AxiosInstance
    public axiosInstanceEntidade: AxiosInstance

    constructor() {
        this.axiosInstanceBase = HttpProvider.createAxiosInstanceBase()
        this.axiosInstancePadraoDeFrete = HttpProvider.createAxiosInstancePadraoDeFrete()
        this.axiosInstanceDashboard = HttpProvider.createAxiosInstanceDashboard()
        this.axiosInstanceCotacao = HttpProvider.createAxiosInstanceCotacao()
        this.axiosInstanceEntidade = HttpProvider.createAxiosInstanceEntidade()
    }

    private static createAxiosInstanceBase() {
        const instance = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
            headers: {
                'Content-Type': 'application/json',
            },
        })

        this.adicionarAthorizationToken(instance)

        return instance
    }


    private static createAxiosInstancePadraoDeFrete() {
        const instance = axios.create({
            baseURL: process.env.VUE_APP_API_URL_PADRAO_DE_FRETE,
            headers: {
                'Content-Type': 'application/json',
            },
        })

        this.adicionarAthorizationToken(instance)

        return instance
    }

    private static createAxiosInstanceDashboard() {
        const instance = axios.create({
            baseURL: process.env.VUE_APP_API_URL_DASHBOARD,
            headers: {
                'Content-Type': 'application/json',
            },
        })

        this.adicionarAthorizationToken(instance)

        return instance
    }

    private static createAxiosInstanceCotacao() {
        const instance = axios.create({
            baseURL: process.env.VUE_APP_API_URL_COTACAO,
            headers: {
                'Content-Type': 'application/json',
            },
        })

        this.adicionarAthorizationToken(instance)

        return instance
    }


    private static createAxiosInstanceEntidade() {
        const instance = axios.create({
            baseURL: process.env.VUE_APP_API_URL_ENTIDADES,
            headers: {
                'Content-Type': 'application/json',
            },
        })

        this.adicionarAthorizationToken(instance)

        return instance
    }

    private static adicionarAthorizationToken(instance: AxiosInstance) {
        if (LocalStorageProvider.retrieve<ConfigDTO>(LocalStorageEnum.CONFIG_KEY).jwt) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${LocalStorageProvider.retrieve<ConfigDTO>(LocalStorageEnum.CONFIG_KEY).jwt}`
        }
    }
}