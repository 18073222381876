















































































































import ErrorHandling from "@/domains/application/ErrorHandling"
import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO"
import { NavegacaoContratacao, NavegacaoContratacaoTipoCadastro } from "@/domains/contratacao/ContratacaoEnum"
import CotacaoBO from "@/domains/cotacao/CotacaoBO"
import CotacaoCompletaDTO from "@/domains/cotacao/CotacaoCompletaDTO"
import CotacaoHelper from "@/domains/cotacao/CotacaoHelper"
import EstadoAPI from "@/domains/estado/EstadoAPI"
import { EstadoDTO } from "@/domains/estado/EstadoDTO"
import MunicipioAPI from "@/domains/municipio/MunicipioAPI"
import { MunicipioDTO } from "@/domains/municipio/MunicipioDTO"
import { Vue, Component } from "vue-property-decorator"
import { mapActions, mapGetters } from "vuex"

@Component({
  computed: {
    ...mapGetters({
      cotacaoCompleta: "cotacaostate/cotacaoCompleta",
      contratacao: 'contratacaostate/contratacao',
    }),
  },
  methods: {
    ...mapActions("cotacaostate", ["coletaobservacao", "entregaobservacao"]),
  },
})
export default class LocalCotacao extends Vue {
  contratacao!: ContratacaoDTO
  navegacaoContratacao = NavegacaoContratacao
  tipoCadastro = NavegacaoContratacaoTipoCadastro
  cotacaoCompleta!: CotacaoCompletaDTO
  estados: EstadoDTO[] = []
  municipios: MunicipioDTO[] = []
  municipiosEntrega: MunicipioDTO[] = []
  carregandoEstados = false
  carregandoMunicipios = false
  carregandoMunicipiosEntrega = false

  mounted() {
    this.buscarEstados()
  }

  buscarEstados() {
    this.carregandoEstados = true
    new EstadoAPI()
      .buscarEstados()
      .then((r) => {
        this.estados = r.data
        this.carregandoEstados = false

        if (this.cotacaoCompleta.cotacao_frete.coleta_uf) {
          this.buscarMunicipios(this.cotacaoCompleta.cotacao_frete.coleta_uf)
        }

        if (this.cotacaoCompleta.cotacao_frete.entrega_uf) {
          this.buscarMunicipiosEntrega(this.cotacaoCompleta.cotacao_frete.entrega_uf)
        }
      })
      .catch((e) => {
        this.carregandoEstados = false
        this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
        this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
      })
  }

  buscarMunicipios(uf: string) {
    const estado = this.estados.find((el) => el.uf === uf)
    if (!estado) return
    this.carregandoMunicipios = true
    new MunicipioAPI()
      .buscarMunicipios(estado.id_estado)
      .then((r) => {
        this.municipios = r.data
        this.carregandoMunicipios = false
      })
      .catch((e) => {
        this.carregandoMunicipios = false
        this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
        this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
      })
  }

  buscarMunicipiosEntrega(uf: string) {
    const estado = this.estados.find((el) => el.uf === uf)
    if (!estado) return
    this.carregandoMunicipiosEntrega = true
    new MunicipioAPI()
      .buscarMunicipios(estado.id_estado)
      .then((r) => {
        this.municipiosEntrega = r.data
        this.carregandoMunicipiosEntrega = false
      })
      .catch((e) => {
        this.carregandoMunicipiosEntrega = false
        this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
        this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
      })
  }

  verificarSePodeProsseguir() {
    try {
      CotacaoBO.validarInformacoesLocalDeColetaEntrega(this.cotacaoCompleta)
      if (CotacaoHelper.verificarInformacoesLocalCotacao(this.cotacaoCompleta)) this.$store.dispatch("contratacaostate/adicionarNovaAba", 'PRODUTOS_COTACAO')
    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(error))
    }
  }

  get estado_coleta() {
    return this.cotacaoCompleta.cotacao_frete.coleta_uf
  }

  set estado_coleta(estado: string) {
    this.$store.dispatch("cotacaostate/coletauf", estado)
  }

  get municipio_coleta() {
    return this.cotacaoCompleta.cotacao_frete.coleta_municipio
  }

  set municipio_coleta(municipio_nome: string) {
    this.$store.dispatch("cotacaostate/coletamunicipio", municipio_nome)
  }

  get estado_entrega() {
    return this.cotacaoCompleta.cotacao_frete.entrega_uf
  }

  set estado_entrega(estado: string) {
    this.$store.dispatch("cotacaostate/entregauf", estado)
  }

  get municipio_entrega() {
    return this.cotacaoCompleta.cotacao_frete.entrega_municipio
  }

  set municipio_entrega(municipio_nome: string) {
    this.$store.dispatch("cotacaostate/entregamunicipio", municipio_nome)
  }
}
