





























































































































































































































































































































































































































import ErrorHandling from "@/domains/application/ErrorHandling";
import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO";
import { MaskHelper } from "@/domains/application/MaskHelper";
import { NavegacaoContratacao } from "@/domains/contratacao/ContratacaoEnum";
import { Vue, Component } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import EstadoAPI from "@/domains/estado/EstadoAPI";
import { EstadoDTO } from "@/domains/estado/EstadoDTO";
import MunicipioAPI from "@/domains/municipio/MunicipioAPI";
import { MunicipioDTO } from "@/domains/municipio/MunicipioDTO";
import EntidadesAPI from "@/domains/entidades/EntidadesAPI";
import BusinessError from "@/domains/application/BusinessError";
import { PlanosEnum } from "@/domains/planos/PlanosEnum";
import ContaAPI from "@/domains/conta/ContaAPI";
import Modal from "@/components/modal/Modal.vue";
import axios from "axios";

@Component({
  components: {
    Modal,
  },
  computed: {
    ...mapGetters({
      contratacao: "contratacaostate/contratacao",
    }),
  },
  methods: {
    ...mapActions("contratacaostate", [
      "setCnpj",
      "setRazaoSocial",
      "setNomeFantasia",
      "setInscricaoEstadual",
      "setRua",
      "setNumero",
      "setCep",
      "setBairro",
    ]),
  },
})
export default class DadosDaEmpresa extends Vue {
  contratacao!: ContratacaoDTO;
  navegacaoContratacao = NavegacaoContratacao;
  estados: EstadoDTO[] = [];
  municipios: MunicipioDTO[] = [];
  planosEnum = PlanosEnum;
  carregandoEstados = false;
  carregandoMunicipios = false;
  carregandoInformacoesSintegra = false;
  buscandoDadosDaEmpresaCNPJ = false;
  finalizouBuscaReceita = false;
  mostrarBotaoAvancar = true;
  mostrandoModalAvisoBuscaEmpresaReceita = false;
  mostrarMaisOpcoesConta = false;
  situacaoEmpresaSintegra = "";
  tipoConta = {
    tipo: "",
    nome: "",
  };

  codigoIndicacao = null;
  codigoValido = false;
  codigoInvalido = false;
  errorMessage = "";
  successMessage = "";

  mounted() {
    this.contratacao.tipo = "TRANSPORTADORA";
    this.buscarEstados();
    if (localStorage.getItem("config")) {
      localStorage.removeItem("config");
      localStorage.removeItem("token");
    }

    if (this.contratacao.id_estado)
      this.buscarMunicipios(this.contratacao.id_estado);
  }

  async buscarDadosDaEmpresaPeloCNPJ() {
    try {
      if (this.buscandoDadosDaEmpresaCNPJ) return;
      if (this.carregandoInformacoesSintegra) return;

      this.buscandoDadosDaEmpresaCNPJ = true;
      this.$store.dispatch(
        "contratacaostate/setfinalizado_cadastro_empresa",
        false
      );
      this.$store.dispatch(
        "contratacaostate/removerAbasAPartirDaAbaInformada",
        "DADOS_DA_EMPRESA"
      );
      this.$store.dispatch("contratacaostate/cleanInformacoesEmpresa");

      let cnpj = this.contratacao.cnpj;
      if (this.contratacao.ramoSelecionado === "transportadora") {
        this.tipoConta.tipo = "TRANSPORTADORA";
        this.tipoConta.nome = "Transportadora";
      } else {
        this.tipoConta.tipo = "TOMADOR";
        this.tipoConta.nome = "Tomador";
      }
      if (!MaskHelper.validarCNPJ(cnpj))
        throw new BusinessError("Informe um CNPJ válido");
      cnpj = MaskHelper.cleanMaskCNPJ(cnpj);
      const response = await new ContaAPI().validarCNPJNaOJO(
        cnpj,
        this.tipoConta.tipo
      );
      if (
        response.data.possui_cadastro &&
        this.contratacao.plano_selecionado !== PlanosEnum.TRIAL
      ) {
        this.mostrarMaisOpcoesConta = true;
        throw new BusinessError(
          `O CNPJ já está em uso, como uma conta do tipo ${this.tipoConta.nome} , para mais informações entre em contato com o suporte do OJO`
        );
      }
      if (
        (response.data.possui_solicitacao || response.data.possui_cadastro) &&
        this.contratacao.plano_selecionado === PlanosEnum.TRIAL
      )
        throw new BusinessError("Você ja possui solicitacao Trial");

      this.$store.dispatch("appstate/setSnackbarWarningVisibility", true);
      this.$store.dispatch(
        "appstate/setSnackbarWarningMessage",
        "Estamos validando seu CNPJ, Aguarde o termino do processamento..."
      );

      this.carregandoInformacoesSintegra = true;
      const responseReceita =
        await new EntidadesAPI().buscarPessoaJuridicaSintegra(cnpj);
      if (responseReceita.data.receitaFederalCnpj.situacaoCadastral !== "ATIVA")
        throw new BusinessError("O CNPJ está invalido na receita federal");
      if (
        responseReceita.data.sintegra &&
        ![
          "ATIVO",
          "Ativo",
          "Habilitado",
          "HABILITADO",
          "ATIVA",
          "Ativa",
        ].includes(responseReceita.data.sintegra.situacaoCadastralAtual)
      ) {
        this.situacaoEmpresaSintegra =
          responseReceita.data.sintegra.situacaoCadastralAtual.toLowerCase();
        this.mostrandoModalAvisoBuscaEmpresaReceita = true;
      }

      this.$store.dispatch(
        "contratacaostate/setInformacoesEmpresaPeloCNPJ",
        responseReceita.data
      );
      this.$store.dispatch(
        "contratacaostate/setfinalizado_cadastro_empresa",
        true
      );

      this.carregandoInformacoesSintegra = false;
      this.finalizouBuscaReceita = true;
      this.mostrarBotaoAvancar = false;
      this.buscandoDadosDaEmpresaCNPJ = false;
      this.mostrarMaisOpcoesConta = false;
      this.watchCNPJ();

      setTimeout(() => {
        this.buscarEstadoPelaUF();
        sessionStorage.setItem("cnpj", this.contratacao.cnpj);
      }, 200);
      this.$store.dispatch("appstate/setSnackbarWarningVisibility", false);
    } catch (error) {
      this.buscandoDadosDaEmpresaCNPJ = false;
      this.finalizouBuscaReceita = false;
      this.carregandoInformacoesSintegra = false;
      this.mostrarBotaoAvancar = true;
      this.$store.dispatch("appstate/setSnackbarWarningVisibility", false);
      this.$store.dispatch(
        "contratacaostate/setfinalizado_cadastro_empresa",
        false
      );
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true);
      this.$store.dispatch(
        "appstate/setSnackbarErrorMessage",
        ErrorHandling.tratarMensagemDeError(error)
      );
    }
    if (this.contratacao.ramoSelecionado === "transportadora") {
      this.contratacao.tipo = "TRANSPORTADORA";
    } else {
      this.contratacao.tipo = "TOMADOR";
    }
  }

  timer: number | null | undefined = null;
  watchCNPJ() {
    this.$watch("contratacao.cnpj", () => {
      if (this.timer) clearTimeout(this.timer);

      if (this.contratacao.cnpj !== "" && this.contratacao.cnpj.length === 18) {
        this.timer = setTimeout(() => {
          this.buscarDadosDaEmpresaPeloCNPJ();
        }, 1000);
      }
    });
  }

  buscarEstadoPelaUF() {
    const ufPessoaJuridica = this.contratacao.uf_pessoa_juridica;
    const estado = this.estados.find((el) => el.uf === ufPessoaJuridica);
    this.$store.dispatch("contratacaostate/setIdEstado", estado?.id_estado);
    if (!estado) return;
    this.buscarMunicipios(estado.id_estado);
  }

  buscarEstados() {
    this.carregandoEstados = true;
    new EstadoAPI()
      .buscarEstados()
      .then((r) => {
        this.estados = r.data;
        this.carregandoEstados = false;
      })
      .catch((e) => {
        this.carregandoEstados = false;
        this.$store.dispatch("appstate/setSnackbarErrorVisibility", true);
        this.$store.dispatch(
          "appstate/setSnackbarErrorMessage",
          ErrorHandling.tratarMensagemDeError(e)
        );
      });
  }

  buscarMunicipios(idEstado: number) {
    this.carregandoMunicipios = true;
    new MunicipioAPI()
      .buscarMunicipios(idEstado)
      .then((r) => {
        this.municipios = r.data;
        this.carregandoMunicipios = false;
        if (this.contratacao.municipio_pessoa_juridica) {
          const municipioPessoaJuridica =
            this.contratacao.municipio_pessoa_juridica;
          const municipio = this.municipios.find(
            (el) => el.nome_normalizado === municipioPessoaJuridica
          );
          this.$store.dispatch(
            "contratacaostate/setIdMunicipio",
            municipio?.id_municipio
          );
        }
      })
      .catch((e) => {
        this.carregandoMunicipios = false;
        this.$store.dispatch("appstate/setSnackbarErrorVisibility", true);
        this.$store.dispatch(
          "appstate/setSnackbarErrorMessage",
          ErrorHandling.tratarMensagemDeError(e)
        );
      });
  }

  validarIndicacao() {
    this.codigoInvalido = false;
    const tipo = this.contratacao.ramoSelecionado.toUpperCase();

    const indication = {
      codigo_indicacao: this.codigoIndicacao,
      tipo_conta: tipo,
      codigo_identificador_celular: "",
    };

    axios
      .post(
        `${process.env.VUE_APP_API_URL_INDICACAO}validar-codigo`,
        indication
      )
      .then((data) => {
        this.successMessage = data.data.message;

        this.codigoValido = true;
        this.codigoInvalido = false;
        sessionStorage.setItem(
          "validCode",
          String(indication.codigo_indicacao)
        );
        sessionStorage.setItem("tipo", tipo);
      })
      .catch((error) => {
        this.errorMessage = String(error.response.data.message);
        this.codigoInvalido = true;
        this.codigoValido = false;
      });
  }

  recarregarPagina() {
    location.reload();
  }

  get id_estado() {
    return this.contratacao.id_estado;
  }

  set id_estado(idEstado: number) {
    this.$store.dispatch("contratacaostate/setIdEstado", idEstado);
  }

  get id_municipio() {
    return this.contratacao.id_municipio;
  }

  set id_municipio(idMunicipio: number) {
    this.$store.dispatch("contratacaostate/setIdMunicipio", idMunicipio);
  }
}
