import BusinessError from "../application/BusinessError"
import { FreteCompletoDTO, FreteProdutoDTO } from "./FreteDTO"

export default class FreteBO {

    public static validarInformacoesDoFrete(freteCompleto: FreteCompletoDTO) {
        if (['', 0, null, undefined, NaN].includes(freteCompleto.Frete.codigo_referencia)) throw new BusinessError('O campo código de referência está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(freteCompleto.Frete.tipo_operacao)) throw new BusinessError('O campo tipo de operação do frete está com valor inválido')
        if (!freteCompleto.Carrocerias || freteCompleto.Carrocerias.length === 0) throw new BusinessError('É necessário escolher uma carroceria')
        if (['', 0, null, undefined, NaN].includes(freteCompleto.Frete.peso) || freteCompleto.Frete.peso > 1000000000 || freteCompleto.Frete.peso < 0) throw new BusinessError('O campo peso está com valor inválido')
    }

    public static validarInformacoesDoProduto(produto: FreteProdutoDTO) {
        if (['', 0, null, undefined, NaN].includes(produto.descricao_generica)) throw new BusinessError('O campo produto está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(produto.embalagem)) throw new BusinessError('O campo embalagem está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(produto.peso) || produto.peso > 1000000000 || produto.peso < 0) throw new BusinessError('O campo peso está com valor inválido')
    }

    public static validarLocalColetaEntrega(freteCompleto: FreteCompletoDTO) {

        if (['', 0, null, undefined, NaN].includes(freteCompleto.Coleta.bairro)) throw new BusinessError('O campo bairro está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(freteCompleto.Coleta.endereco)) throw new BusinessError('O campo endereço está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(freteCompleto.Coleta.numero)) throw new BusinessError('O campo número está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(freteCompleto.Coleta.data_prevista)) throw new BusinessError('O campo data prevista da coleta está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(freteCompleto.Coleta.municipio)) throw new BusinessError('O campo município está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(freteCompleto.Coleta.uf)) throw new BusinessError('O campo estado está com valor inválido')


        if (['', 0, null, undefined, NaN].includes(freteCompleto.Entrega.bairro)) throw new BusinessError('O campo bairro está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(freteCompleto.Entrega.endereco)) throw new BusinessError('O campo endereço está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(freteCompleto.Entrega.numero)) throw new BusinessError('O campo número está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(freteCompleto.Entrega.data_prevista)) throw new BusinessError('O campo data prevista da coleta está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(freteCompleto.Entrega.municipio)) throw new BusinessError('O campo município está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(freteCompleto.Entrega.uf)) throw new BusinessError('O campo estado está com valor inválido')
    }

    public static validarListaProduto(produtos: FreteProdutoDTO[]) {
        if (!produtos || produtos.length === 0) throw new BusinessError('É necessário informar os produtos deste frete')
    }

    public static validarInformacoesFinaisFrete(freteCompleto: FreteCompletoDTO, freteMotorista: boolean, freteTransportadora: boolean) {
        if (['', 0, null, undefined, NaN].includes(freteCompleto.Frete.venda_por_unidade)) throw new BusinessError('O campo venda por unidade está com valor inválido')
        if (freteMotorista && ['', 0, null, undefined, NaN].includes(freteCompleto.Frete.frete_motorista_valor_text)) throw new BusinessError('O campo valor do frete motorista está com valor inválido')
        if (freteTransportadora && ['', 0, null, undefined, NaN].includes(freteCompleto.Frete.frete_transportadora_valor_text)) throw new BusinessError('O campo valor do frete transportadora está com valor inválido')
        if (!freteMotorista && !freteTransportadora) throw new BusinessError('Você precisa informar ao menos um valor para transportadora ou motorista')
    }
}