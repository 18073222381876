import { OldFilterDTO } from "./OldFilterDTO"
import { SortDTO } from "./SortDTO"

export class UrlHelper {

    public static buildFilterWithSortAndSkipTake(skip: number, take: number, sort: SortDTO, filters: OldFilterDTO) {


        const filtersCopy = filters

        const firstFilterProp = Object.keys(filtersCopy)[0]
        let filter = `["${filtersCopy[firstFilterProp].field}", "${filtersCopy[firstFilterProp].rule}", "${filtersCopy[firstFilterProp].value}"]`

        delete filtersCopy[firstFilterProp]


        for (const prop in filtersCopy) {
            if (Object.prototype.hasOwnProperty.call(filtersCopy, prop)) {
                const value = filtersCopy[prop].rule === 'in' ? JSON.stringify(filtersCopy[prop].value) : `"${filtersCopy[prop].value}"`
                filter += `,"AND",["${filtersCopy[prop].field}", "${filtersCopy[prop].rule}", ${value}]`
            }
        }

        filter = `[${filter}]`
        const sortSelector = `[${JSON.stringify(sort)}]`

        return `skip=${skip}&take=${take}&sort=${encodeURIComponent(sortSelector)}&filter=${encodeURIComponent(filter)}`
    }
}