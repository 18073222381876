import { OldFilterDTO } from "../application/OldFilterDTO"

export default class CarroceriaHelper {


    public static getFilter(): OldFilterDTO {
        return {
            descricao: { field: "descricao", rule: "contains", value: "%%" }
        }
    }

}