




















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Modal extends Vue {
  @Prop({ required: true, type: Boolean, default: false }) readonly isShowingModal!: boolean
  @Prop({ required: false, type: String, default: '' }) readonly titleModal!: string
  @Prop({ required: false, type: String, default: '600px' }) readonly width!: string
  @Prop({ required: false, type: String, default: '10' }) readonly zIndex!: string

}
