/* eslint-disable no-async-promise-executor */
import { createCreditCard, createToken, getFlagCard, IuguCreditCard, PaymentMethod } from "./iugu"

export default class IuguAPI {


    public static newCreditCard() {
        return new Promise<IuguCreditCard>((resolve, reject) => {
            const creditCard: IuguCreditCard = {
                cardNumber: "",
                dueMonth: "",
                dueYear: "",
                name: "",
                lastName: "",
                verificationValue: ""
            }
            try {
                resolve(creditCard)
            } catch (error) {
                reject(error)
            }
        })
    }

    public static prepareCreditCard(creditCard: IuguCreditCard) {
        return new Promise<IuguCreditCard>((resolve, reject) => {
            try {
                const nameStrings = creditCard.name.split(' ')
                const prepare = {
                    cardNumber: creditCard.cardNumber,
                    dueMonth: creditCard.dueMonth,
                    dueYear: creditCard.dueYear,
                    name: '',
                    lastName: '',
                    verificationValue: creditCard.verificationValue
                }

                for (let I = 0; I < nameStrings.length - 1; I++) {
                    const element = nameStrings[I]
                    prepare.name += `${element} `

                }

                prepare.lastName = nameStrings[nameStrings.length - 1]

                resolve(prepare)
            } catch (error) {
                reject(error)
            }
        })
    }

    public static newIuguCreditCard(creditCard: IuguCreditCard) {
        return new Promise<unknown>((resolve, reject) => {
            try {
                const iuguCreditCard = createCreditCard(creditCard)
                resolve(iuguCreditCard)
            } catch (error) {
                reject(error)
            }
        })
    }

    public static newIuguCreditCardToken(creditCard: unknown) {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const token = await createToken(creditCard)
                resolve(token)
            } catch (error) {
                reject(error)
            }
        })
    }

    public static preparePaymentMethod(accountId: number, token: string, description: string) {
        return new Promise<PaymentMethod>((resolve, reject) => {
            try {
                const prepare = {
                    id_conta: accountId,
                    token: token,
                    descricao: description,
                }
                resolve(prepare)
            } catch (error) {
                reject(error)
            }
        })
    }

    public static getFlagCard(cardNumber: string) {
        return new Promise<string>((resolve, reject) => {
            try {
                const flag = getFlagCard(cardNumber)
                resolve(flag)
            } catch (error) {
                reject(error)
            }
        })
    }
}