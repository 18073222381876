





































































import ErrorHandling from "@/domains/application/ErrorHandling"
import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO"
import { NavegacaoContratacao, NavegacaoContratacaoTipoCadastro } from "@/domains/contratacao/ContratacaoEnum"
import FreteBO from "@/domains/frete/FreteBO"
import { FreteCompletoDTO } from "@/domains/frete/FreteDTO"
import FreteHelper from "@/domains/frete/FreteHelper"
import { Vue, Component, Watch } from "vue-property-decorator"
import { mapActions, mapGetters } from "vuex"

@Component({
  computed: {
    ...mapGetters({
      freteCompleto: "fretestate/freteCompleto",
      contratacao: 'contratacaostate/contratacao',
    }),
  },
  methods: {
    ...mapActions("fretestate", [
      "setColetaUf",
      "setColetaMunicipio",
      "setColetaData_prevista",
      "setEntregaUf",
      "setEntregaMunicipio",
      "setData_prevista",
      "setRoteiro",
    ]),
  },
})
export default class FinalizacaoFrete extends Vue {
  contratacao!: ContratacaoDTO
  navegacaoContratacao = NavegacaoContratacao
  tipoCadastro = NavegacaoContratacaoTipoCadastro
  freteCompleto!: FreteCompletoDTO
  freteMotorista = true
  freteTransportadora = false
  money = {
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    suffix: '',
    precision: 2,
  }

  @Watch("frete_motorista")
  onChangeFreteMotorista(newValue: string) {
    if (newValue === 'Não') this.frete_motorista_valor = "0"
  }

  @Watch("frete_transportadora")
  onChangeFreteTransportadora(newValue: string) {
    if (newValue === 'Não') this.frete_transportadora_valor = "0"
  }

  verificarSePodeProsseguir() {
    try {
      FreteBO.validarInformacoesFinaisFrete(this.freteCompleto, this.freteMotorista, this.freteTransportadora)
      if (FreteHelper.verificarInformacoesFinais(this.freteCompleto)) this.$store.dispatch("contratacaostate/adicionarNovaAba", 'DADOS_RESPONSAVEL')

      setTimeout(() => {
        if (this.contratacao.abasCadastro.includes('DADOS_RESPONSAVEL') && this.contratacao.usuario_logado)
          this.$store.dispatch("contratacaostate/adicionarNovaAba", 'DESEJA_CADASTRAR_INFORMACOES_ADICIONAIS')
      }, 200)

    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(error))
    }
  }

  get venda_por_unidade() {
    return this.freteCompleto.Frete.venda_por_unidade
  }

  set venda_por_unidade(venda_por_unidade: string) {
    this.$store.dispatch("fretestate/setVenda_por_unidade", venda_por_unidade)
  }

  get frete_motorista() {
    return this.freteCompleto.Frete.frete_motorista
  }

  set frete_motorista(frete_motorista: string) {
    this.$store.dispatch("fretestate/setFrete_motorista", frete_motorista)
  }

  get frete_transportadora() {
    return this.freteCompleto.Frete.frete_transportadora
  }

  set frete_transportadora(frete_transportadora: string) {
    this.$store.dispatch("fretestate/setFrete_transportadora", frete_transportadora)
  }

  get frete_motorista_valor() {
    return this.freteCompleto.Frete.frete_motorista_valor_text
  }

  set frete_motorista_valor(frete_motorista_valor: string) {
    // const freteMotoristaValor = frete_motorista_valor.replace("R$", "").replace(",", "")
    this.$store.dispatch("fretestate/setFrete_motorista_valor", frete_motorista_valor)
  }

  get frete_transportadora_valor() {
    return this.freteCompleto.Frete.frete_transportadora_valor_text
  }

  set frete_transportadora_valor(frete_transportadora_valor: string) {
    // const freteTransportadora = frete_transportadora_valor.replace("R$", "").replace(",", "")
    this.$store.dispatch("fretestate/setFrete_transportadora_valor", frete_transportadora_valor)
  }

}
