import HttpProvider from "@/providers/HttpProvider"
import { AxiosResponse } from "axios"
import { MunicipioDTO } from "./MunicipioDTO"

class MunicipioAPI {

    private httpProvider: HttpProvider

    constructor() {
        this.httpProvider = new HttpProvider()
    }

    public buscarMunicipios(idEstado: number): Promise<AxiosResponse<Array<MunicipioDTO>>> {
        return this.httpProvider.axiosInstanceBase.get(`/municipio/${idEstado}`)
    }

}


export default MunicipioAPI
