






















































































































































































































































































































































import ErrorHandling from "@/domains/application/ErrorHandling"
import { SortDTO } from "@/domains/application/SortDTO"
import CarroceriaAPI from "@/domains/carroceria/CarroceriaAPI"
import CarroceriaHelper from "@/domains/carroceria/CarroceriaHelper"
import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO"
import { NavegacaoContratacao } from "@/domains/contratacao/ContratacaoEnum"
import { FreteCarroceriaDTO } from "@/domains/frete/FreteDTO"
import { PlanosEnum, PlanosRamosEnum } from "@/domains/planos/PlanosEnum"
import { Vue, Component } from "vue-property-decorator"
import { mapActions, mapGetters } from "vuex"

@Component({
  computed: {
    ...mapGetters({
      contratacao: "contratacaostate/contratacao",
    }),
  },
  methods: {
    ...mapActions("contratacaostate", [
      "setRegimeTributario",
      "setFaturamentoAnual",
      "setDataAberturaEmpresa",
      "setFuncionarios",
      "setSetorEconomico",
      "setOutroSegmento",
      "setOutroSetorEconomico",
      "setOutrosTiposCargas",
      "setTransportadora_seguradora",
      "setTransportadora_vigencia_seguro",
      "setTransportadora_cobertura_seguro_por_carga",
      "setTransportadora_frota_propria_numero_caminhoes_cavalos",
      "setTransportadora_frota_propria_numero_motoristas",
      "setTransportadora_frota_agregada_numero_caminhoes_cavalos",
      "setTransportadora_frota_agregada_numero_motoristas",
      "setFormaDePagamento",
    ]),
  },
})
export default class InformacoesAdicionais extends Vue {
  contratacao!: ContratacaoDTO
  navegacaoContratacao = NavegacaoContratacao
  planosRamos = PlanosRamosEnum
  carrocerias: FreteCarroceriaDTO[] = []
  planosEnum = PlanosEnum

  tipoCargas = ["A Granel", "Carga Seca (Embalados/Paletizados...)", "Perigosas", "Frigorificadas", "Indivisíveis de grande porte", "Vivas", "Outros"]

  listaSegmentos = [
    "Agronegócio",
    "Alimentos",
    "Bebidas",
    "Químico",
    "Derivado de Petróleo e Biocombustível",
    "Siderurgica",
    "Metalurgica",
    "Celulose/Papel",
    "Veículos Automotores",
    "Máquinas/equipamentos",
    "Borracha/Plástico",
    "Mineração",
    "Madeira",
    "Construção",
    "Extração de minerais",
    "Textil",
    "Outros",
  ]
  carregandoCarrocerias = false
  skip = 0
  take = 100
  sort: SortDTO = {
    selector: "descricao",
    desc: false,
  }
  filters = { ...CarroceriaHelper.getFilter() }

  mounted() {
    this.buscarCarrocerias()
  }

  buscarCarrocerias() {
    this.carregandoCarrocerias = true
    new CarroceriaAPI()
      .buscarCarrocerias(this.skip, this.take, this.sort, this.filters)
      .then((r) => {
        const carrocerias = r.data
        carrocerias.forEach((element) => {
          this.carrocerias.push({ descricao_carroceria: element.descricao })
        })
        this.carregandoCarrocerias = false
      })
      .catch((e) => {
        this.carregandoCarrocerias = false
        this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
        this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
      })
  }

  get segmentos() {
    return this.contratacao.segmentos
  }

  set segmentos(segmentos: string[]) {
    this.$store.dispatch("contratacaostate/setSegmentos", segmentos)
  }

  get tipo_cargas() {
    return this.contratacao.tipo_cargas
  }

  set tipo_cargas(tipoCargas: string[]) {
    this.$store.dispatch("contratacaostate/setTipoCargasTransportadas", tipoCargas)
  }

  get produtor_rural() {
    return this.contratacao.produtor_rural
  }

  set produtor_rural(produtorRural: string) {
    this.$store.dispatch("contratacaostate/setProdutorRural", produtorRural)
  }

  get cooperativa() {
    return this.contratacao.cooperativa
  }

  set cooperativa(cooperativa: string) {
    this.$store.dispatch("contratacaostate/setCooperativa", cooperativa)
  }

  get realiza_transporte_container() {
    return this.contratacao.realiza_transporte_container
  }

  set realiza_transporte_container(realizaTransportes: string) {
    this.$store.dispatch("contratacaostate/setRealizaTransportesEmContainer", realizaTransportes)
  }

  get transportadora_possui_seguro_ambiental() {
    return this.contratacao.transportadora_possui_seguro_ambiental
  }

  set transportadora_possui_seguro_ambiental(transportadora_possui_seguro_ambiental: string | undefined) {
    this.$store.dispatch("contratacaostate/setTransportadora_possui_seguro_ambiental", transportadora_possui_seguro_ambiental)
  }

  get transportadora_possui_gerenciadora_de_risco() {
    return this.contratacao.transportadora_possui_gerenciadora_de_risco
  }

  set transportadora_possui_gerenciadora_de_risco(transportadora_possui_gerenciadora_de_risco: string | undefined) {
    this.$store.dispatch("contratacaostate/setTransportadora_possui_gerenciadora_de_risco", transportadora_possui_gerenciadora_de_risco)
  }

  get transportadora_perfil_carrocerias() {
    return this.contratacao.transportadora_perfil_carrocerias
  }

  set transportadora_perfil_carrocerias(transportadora_perfil_carrocerias: string[] | undefined) {
    this.$store.dispatch("contratacaostate/settransportadora_perfil_carrocerias", transportadora_perfil_carrocerias)
  }

  get transportadora_gerenciador_de_risco() {
    return this.contratacao.transportadora_gerenciador_de_risco
  }

  set transportadora_gerenciador_de_risco(transportadora_gerenciador_de_risco: string | undefined) {
    this.$store.dispatch("contratacaostate/settransportadora_gerenciador_de_risco", transportadora_gerenciador_de_risco)
  }

  get regime_tributario() {
    return this.contratacao.regime_tributario
  }

  set regime_tributario(regime_tributario: string) {
    this.$store.dispatch("contratacaostate/setRegimeTributario", regime_tributario)
  }

  get faturamento_anual() {
    return this.contratacao.faturamento_anual
  }

  set faturamento_anual(faturamento_anual: string) {
    this.$store.dispatch("contratacaostate/setFaturamentoAnual", faturamento_anual)
  }

  get funcionarios() {
    return this.contratacao.funcionarios
  }

  set funcionarios(funcionarios: string) {
    this.$store.dispatch("contratacaostate/setFuncionarios", funcionarios)
  }

  get data_abertura_empresa() {
    return this.contratacao.data_abertura_empresa
  }

  set data_abertura_empresa(data_abertura_empresa: string) {
    this.$store.dispatch("contratacaostate/setDataAberturaEmpresa", data_abertura_empresa)
  }

}
