import HttpProvider from "@/providers/HttpProvider"
import { AxiosResponse } from "axios"
import BuscaTransportadoraDTO, { FiltroBuscaTransportadoraDTO } from "./BuscaTransportadoraDTO"

class BuscarTransportadoraAPI {

    private httpProvider: HttpProvider

    constructor() {
        this.httpProvider = new HttpProvider()
    }

    public buscarTransportadoras(filtro: FiltroBuscaTransportadoraDTO): Promise<AxiosResponse<Array<BuscaTransportadoraDTO>>> {
        return this.httpProvider.axiosInstanceBase.post(`/tomador-transportadora-parceira/v3/todas`, filtro)
    }

}


export default BuscarTransportadoraAPI
