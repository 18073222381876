
export default class ContratacaoHelper {
    public static traduzirSituacoes(plan: string) {
        const plans = {
            SPOT: 'Spot',
            STANDARD: 'Standard',
            ADVANCED: 'Advanced',
            PREMIUM: 'Premium',
            GRATIS: 'Grátis',
            TRANSPORTADORA_STANDARD: 'Standard',
            TRANSPORTADORA_ADVANCED: 'Advanced',
            TRANSPORTADORA_PREMIUM: 'Premium',
            TRIAL: 'Trial'
        }
        const planSelected = plan as keyof typeof plans
        return plans[planSelected] ? plans[planSelected] : "Plano não encontrado"

    }
}