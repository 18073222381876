



























































































































































































































































































































































import ErrorHandling from "@/domains/application/ErrorHandling"
import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO"
import {
  NavegacaoContratacao,
  NavegacaoContratacaoTipoCadastro,
} from "@/domains/contratacao/ContratacaoEnum"
import EstadoAPI from "@/domains/estado/EstadoAPI"
import { EstadoDTO } from "@/domains/estado/EstadoDTO"
import FreteBO from "@/domains/frete/FreteBO"
import { FreteCompletoDTO } from "@/domains/frete/FreteDTO"
import FreteHelper from "@/domains/frete/FreteHelper"
import MunicipioAPI from "@/domains/municipio/MunicipioAPI"
import { MunicipioDTO } from "@/domains/municipio/MunicipioDTO"
import DateTimeProvider from "@/providers/DateTimeProvider"
import { Vue, Component, Watch } from "vue-property-decorator"
import { mapActions, mapGetters } from "vuex"


@Component({
  computed: {
    ...mapGetters({
      freteCompleto: "fretestate/freteCompleto",
      contratacao: 'contratacaostate/contratacao',
    }),
  },
  methods: {
    ...mapActions("fretestate", [
      "setColetaUf",
      "setColetaMunicipio",
      "setEntregaUf",
      "setEntregaMunicipio",
      "setRoteiro",
      "setColetaEndereco",
      "setColetaBairro",
      "setColetaNumero",
      "setEndereco",
      "setBairro",
      "setNumero",
      "setTelefone_celular",
      "setCnpj",
      "setEmail",
      "setNome",
    ]),
  },
})
export default class LocalFrete extends Vue {
  contratacao!: ContratacaoDTO
  navegacaoContratacao = NavegacaoContratacao;
  tipoCadastro = NavegacaoContratacaoTipoCadastro;
  freteCompleto!: FreteCompletoDTO
  estados: EstadoDTO[] = []
  municipios: MunicipioDTO[] = []
  municipiosEntrega: MunicipioDTO[] = []
  carregandoEstados = false
  carregandoMunicipios = false
  carregandoMunicipiosEntrega = false

  mounted() {
    this.buscarEstados()
  }

  buscarEstados() {
    this.carregandoEstados = true
    new EstadoAPI().buscarEstados().then(r => {
      this.estados = r.data
      this.carregandoEstados = false

      if (this.freteCompleto.Coleta.uf) {
        this.buscarMunicipios(this.freteCompleto.Coleta.uf)
      }

      if (this.freteCompleto.Entrega.uf) {
        this.buscarMunicipiosEntrega(this.freteCompleto.Entrega.uf)
      }

    }).catch(e => {
      this.carregandoEstados = false
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
    })
  }

  buscarMunicipios(uf: string) {
    const estado = this.estados.find(el => el.uf === uf)
    if (!estado) return
    this.carregandoMunicipios = true
    new MunicipioAPI().buscarMunicipios(estado.id_estado).then(r => {
      this.municipios = r.data
      this.carregandoMunicipios = false
    }).catch(e => {
      this.carregandoMunicipios = false
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
    })
  }

  buscarMunicipiosEntrega(uf: string) {
    const estado = this.estados.find(el => el.uf === uf)
    if (!estado) return
    this.carregandoMunicipiosEntrega = true
    new MunicipioAPI().buscarMunicipios(estado.id_estado).then(r => {
      this.municipiosEntrega = r.data
      this.carregandoMunicipiosEntrega = false
    }).catch(e => {
      this.carregandoMunicipiosEntrega = false
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
    })
  }


  @Watch("freteCompleto.Coleta.coleta_imediata")
  onChangeColetaImediata(newValue: string) {
    if (newValue === "Sim")
      this.$store.dispatch("fretestate/setColetaData_prevista", DateTimeProvider.now("YYYY-MM-DDTHH:mm"))
  }

  verificarSePodeProsseguir() {
    try {
      FreteBO.validarLocalColetaEntrega(this.freteCompleto)
      if (FreteHelper.verificarInformacoesLocalFrete(this.freteCompleto)) this.$store.dispatch("contratacaostate/adicionarNovaAba", 'PRODUTOS_FRETE')
    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(error))
    }
  }

  alterarEntregaDiaTodo() {
    if (this.freteCompleto.Entrega.entrega_dia_todo === "Não") {
      this.hora_prevista_entrega = '00:00'
    }
  }

  alterarColetaDiaTodo() {
    if (this.freteCompleto.Coleta.coleta_dia_todo === "Não") {
      this.$store.dispatch("fretestate/setColeta_imediata", 'Não')
      this.hora_prevista_coleta = '00:00'
    }
  }

  alterarColetaImediata() {
    if (this.freteCompleto.Coleta.coleta_imediata === "Não")
      this.$store.dispatch("fretestate/setColetaDiaTodo", 'Não')
  }

  get coleta_imediata() {
    return this.freteCompleto.Coleta.coleta_imediata
  }

  set coleta_imediata(coleta_imediata: string) {
    this.$store.dispatch("fretestate/setColeta_imediata", coleta_imediata)
  }

  get estado_coleta() {
    return this.freteCompleto.Coleta.uf
  }

  set estado_coleta(estado: string) {
    this.$store.dispatch("fretestate/setColetaUf", estado)
  }

  get municipio_coleta() {
    return this.freteCompleto.Coleta.municipio_codigo_ibge
  }

  set municipio_coleta(municipio_codigo_ibge: string) {
    const municipio = this.municipios.find(el => el.codigo_ibge === Number(municipio_codigo_ibge))
    this.$store.dispatch("fretestate/setColetaLatitude", municipio?.latitude)
    this.$store.dispatch("fretestate/setColetaLongitude", municipio?.longitude)
    this.$store.dispatch("fretestate/setColetaMunicipio", municipio?.nome)
    this.$store.dispatch("fretestate/setColetaMunicipio_codigo_ibge", municipio_codigo_ibge)
  }

  get estado_entrega() {
    return this.freteCompleto.Entrega.uf
  }

  set estado_entrega(estado: string) {
    this.$store.dispatch("fretestate/setUf", estado)
  }

  get municipio_entrega() {
    return this.freteCompleto.Entrega.municipio_codigo_ibge
  }

  set municipio_entrega(municipio_codigo_ibge: string) {
    const municipio = this.municipiosEntrega.find(el => el.codigo_ibge === Number(municipio_codigo_ibge))
    this.$store.dispatch("fretestate/setLatitude", municipio?.latitude)
    this.$store.dispatch("fretestate/setLongitude", municipio?.longitude)
    this.$store.dispatch("fretestate/setMunicipio", municipio?.nome)
    this.$store.dispatch("fretestate/setMunicipio_codigo_ibge", municipio_codigo_ibge)
  }

  get coleta_dia_todo() {
    return this.freteCompleto.Coleta.coleta_dia_todo
  }

  set coleta_dia_todo(coletaDiaTodo: string) {
    this.$store.dispatch("fretestate/setColetaDiaTodo", coletaDiaTodo)
  }

  get entrega_dia_todo() {
    return this.freteCompleto.Entrega.entrega_dia_todo
  }

  set entrega_dia_todo(entregaDiaTodo: string) {
    this.$store.dispatch("fretestate/setEntregaDiaTodo", entregaDiaTodo)
  }

  get data_prevista_coleta() {
    return this.freteCompleto.Coleta.data_prevista.split('T')[0]
  }

  set data_prevista_coleta(value: string) {
    if (value) {
      const hour = this.freteCompleto.Coleta.data_prevista.split('T')[0]
      const dateTime = `${value}T${hour}`
      this.$store.dispatch("fretestate/setColetaData_prevista", dateTime)
    }
  }

  get hora_prevista_coleta() {
    return this.freteCompleto.Coleta.data_prevista.split('T')[1]
  }

  set hora_prevista_coleta(value: string) {
    if (value) {
      const date = this.freteCompleto.Coleta.data_prevista.split('T')[1]
      const dateTime = `${date}T${value}`
      this.$store.dispatch("fretestate/setColetaData_prevista", dateTime)
    }
  }

  get data_prevista_entrega() {
    return this.freteCompleto.Entrega.data_prevista.split('T')[0]
  }

  set data_prevista_entrega(value: string) {
    if (value) {
      const hour = this.freteCompleto.Entrega.data_prevista.split('T')[0]
      const dateTime = `${value}T${hour}`
      this.$store.dispatch("fretestate/setData_prevista", dateTime)
    }
  }

  get hora_prevista_entrega() {
    return this.freteCompleto.Entrega.data_prevista.split('T')[1]
  }

  set hora_prevista_entrega(value: string) {
    if (value) {
      const date = this.freteCompleto.Entrega.data_prevista.split('T')[1]
      const dateTime = `${date}T${value}`
      this.$store.dispatch("fretestate/setData_prevista", dateTime)
    }
  }

}
