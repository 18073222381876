import BuscaTransportadoraDTO from "../transportadora/BuscaTransportadoraDTO"
import CotacaoCompletaDTO, { CotacaoProdutoDTO } from "./CotacaoCompletaDTO"

export default class CotacaoHelper {
    public static verificarInformacoesCotacao(cotacao: CotacaoCompletaDTO) {
        const informacoes_basicas = (
            !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.codigo_referencia) &&
            cotacao.carrocerias.length !== 0 &&
            !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.peso))


        const cotacao_frete_valor = (cotacao.cotacao_frete.tipo_cotacao === 'BUSCA_TRANSPORTADORA' && cotacao.cotacao_frete.tipo_precificacao === 'VALOR_COMPOSTO' && cotacao.cotacao_frete.valor_habilitado === 'Sim') && !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.valor)
        const cotacao_frete_vale_pedagio_valor = (cotacao.cotacao_frete.tipo_cotacao === 'BUSCA_TRANSPORTADORA' && cotacao.cotacao_frete.tipo_precificacao === 'VALOR_COMPOSTO' && cotacao.cotacao_frete.vale_pedagio_habilitado === 'Sim') && !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.vale_pedagio_valor)
        const cotacao_frete_ad_valorem_valor = (cotacao.cotacao_frete.tipo_cotacao === 'BUSCA_TRANSPORTADORA' && cotacao.cotacao_frete.tipo_precificacao === 'VALOR_COMPOSTO' && cotacao.cotacao_frete.ad_valorem_habilitado === 'Sim') && !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.ad_valorem_valor)
        const cotacao_frete_estimativa_entrega_dias = (cotacao.cotacao_frete.tipo_cotacao === 'BUSCA_TRANSPORTADORA' && cotacao.cotacao_frete.tipo_precificacao === 'VALOR_COMPOSTO' && cotacao.cotacao_frete.estimativa_entrega_habilitado === 'Sim') && !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.estimativa_entrega_dias)
        const cotacao_frete_valor_all_in = (cotacao.cotacao_frete.tipo_cotacao === 'BUSCA_TRANSPORTADORA' && cotacao.cotacao_frete.tipo_precificacao === 'ALL_IN') && !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.valor)

        return informacoes_basicas
    }

    public static verificarInformacoesLocalCotacao(cotacao: CotacaoCompletaDTO) {
        return (
            !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.coleta_uf) &&
            !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.coleta_municipio) &&
            !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.entrega_uf) &&
            !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.entrega_municipio)
        )
    }

    public static verificarInformacoesProdutos(produtos: CotacaoProdutoDTO[]) {
        return (produtos.length !== 0)
    }


    public static verificarFinalizacaoDaCotacao(cotacao: CotacaoCompletaDTO) {
        return (
            !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.tipo_preco_frete) &&
            !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete._data_inicio_cotacao) &&
            !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete._hora_inicio_cotacao) &&
            !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete._data_termino_cotacao) &&
            !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete._hora_termino_cotacao) &&
            !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.data_termino_vigencia) &&
            !['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.data_inicio_vigencia) &&
            cotacao.convites.length !== 0
            // transportadoras.length !== 0 &&
        )
    }
}