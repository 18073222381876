






































































































































































































































































































































































































































































import DadosDaEmpresa from "@/components/contratacao/conta/DadosDaEmpresa.vue";
import DadosDoResponsavel from "@/components/contratacao/conta/DadosDoResponsavel.vue";
import InformacoesAdicionais from "@/components/contratacao/conta/InformacoesAdicionais.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO";
import DesejaCadastrarFreteCotacao from "@/components/contratacao/conta/DesejaCadastrarFreteCotacao.vue";
import Pagamento from "@/components/contratacao/conta/Pagamento.vue";
import { NavegacaoContratacao } from "@/domains/contratacao/ContratacaoEnum";
import { PlanosEnum } from "@/domains/planos/PlanosEnum";
import AutenticacaoAPI from "@/domains/autenticacao/AutenticacaoAPI";
import LocalStorageProvider, {
  LocalStorageEnum,
} from "@/providers/LocalStorageProviders";
import ConfigDTO from "@/domains/application/ConfigDTO";
import JWTDTO from "@/domains/application/JWTDTO";
import md5 from "md5";
import ErrorHandling from "@/domains/application/ErrorHandling";
import { MaskHelper } from "@/domains/application/MaskHelper";
import jwt_decode from "jwt-decode";
import FinalizacaoCotacao from "../cotacao/FinalizacaoCotacao.vue";
import InformacoesCotacao from "../cotacao/InformacoesCotacao.vue";
import LocalCotacao from "../cotacao/LocalCotacao.vue";
import ProdutosCotacao from "../cotacao/ProdutosCotacao.vue";
import FinalizacaoFrete from "../frete/FinalizacaoFrete.vue";
import InformacoesFrete from "../frete/InformacoesFrete.vue";
import LocalFrete from "../frete/LocalFrete.vue";
import ProdutosFrete from "../frete/ProdutosFrete.vue";
import { FreteCompletoDTO } from "@/domains/frete/FreteDTO";
import FreteHelper from "@/domains/frete/FreteHelper";
import CotacaoHelper from "@/domains/cotacao/CotacaoHelper";
import CotacaoCompletaDTO from "@/domains/cotacao/CotacaoCompletaDTO";
import CadastroUsuarioDTO from "@/domains/usuario/CadastroUsuarioDTO";
import CadastroUsuarioBO from "@/domains/usuario/CadastroUsuarioBO";
import CadastroUsuarioFactory from "@/domains/usuario/CadastroUsuarioFactory";
import UsuarioAPI from "@/domains/usuario/UsuarioAPI";
import Shepherd from "shepherd.js";
import BusinessError from "@/domains/application/BusinessError";
import axios from "axios";

@Component({
  components: {
    DadosDoResponsavel,
    DadosDaEmpresa,
    InformacoesAdicionais,
    DesejaCadastrarFreteCotacao,
    Pagamento,
    InformacoesCotacao,
    LocalCotacao,
    ProdutosCotacao,
    FinalizacaoCotacao,
    InformacoesFrete,
    LocalFrete,
    ProdutosFrete,
    FinalizacaoFrete,
  },
  computed: {
    ...mapGetters({
      contratacao: "contratacaostate/contratacao",
      freteCompleto: "fretestate/freteCompleto",
      cotacaoCompleta: "cotacaostate/cotacaoCompleta",
    }),
  },
  methods: {
    ...mapActions("contratacaostate", [
      "setFormaDePagamento",
      "setNomeCadastroNovaConta",
      "setSobrenomeCadastroNovaConta",
    ]),
  },
})
export default class CadastroConta extends Vue {
  contratacao!: ContratacaoDTO;
  freteCompleto!: FreteCompletoDTO;
  cotacaoCompleta!: CotacaoCompletaDTO;
  navegacaoContratacao = NavegacaoContratacao;
  planosEnum = PlanosEnum;
  tour!: Shepherd.Tour;
  tourInformacoesAdicionais!: Shepherd.Tour;
  tourFrete!: Shepherd.Tour;
  tourCotacao!: Shepherd.Tour;
  tourDadosDosResponsaveis!: Shepherd.Tour;
  page = 0;
  realizandoLogin = false;
  mostrarSenha = false;
  mostrarConfirmarSenha = false;
  desejaCadastrarInformacoesAdicionais = false;
  senhaLogin = "";
  confirmarSenha = "";
  codigo_ativacao = "";
  celularVerificacao = "";

  usuario: CadastroUsuarioDTO = {
    nome: "",
    sobrenome: "",
    email: "",
    celular: "",
    cpf: "",
    ativo: "",
    senha: "",
    id_termo_uso_plataforma: 0,
    apelido: "",
  };

  mounted() {
    setTimeout(() => {
      if (this.contratacao.usuario_logado) {
        this.buscarInformacoesDoUsuario();
      }
      this.initTour();
    }, 200);
  }

  initTour() {
    this.tour = new Shepherd.Tour({
      useModalOverlay: true,
      exitOnEsc: true,
      defaultStepOptions: {
        classes: "shadow-md bg-purple-dark",
        scrollTo: true,
      },
    });

    this.tour.addStep({
      id: "contratacao-cadastro-dados-empresa",

      text: `
        <div style="display:flex; flex-direction:column">
          <span>Informe o <b>CNPJ</b> da sua empresa e clique no icone ✔️ ou no botão avançar.</span>
          <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
        </div>
        `,

      attachTo: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        element: document.getElementById("contratacao-cadastro-dados-empresa")!,
        on: "bottom",
      },

      classes: "example-step-extra-class",

      buttons: [
        {
          text: "OK",
          action: this.tour.hide,
        },
      ],
    });

    if (this.contratacao.plano_selecionado === PlanosEnum.TRIAL)
      this.tour.addStep({
        id: "contratacao-cadastro-deseja-cadastrar-frete-cotacao",

        text: `
          <div style="display:flex; flex-direction:column">
            <span>Escolha uma opção para prosseguir o cadastro.</span>
            <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
          </div>
          `,

        attachTo: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          element: document.getElementById(
            "contratacao-cadastro-deseja-cadastrar-frete-cotacao"
          )!,
          on: "bottom",
        },

        classes: "example-step-extra-class",

        buttons: [
          {
            text: "OK",
            action: this.tour.hide,
          },
        ],
      });

    this.tour.addStep({
      id: "contratacao-cadastro-dados-responsavel",

      text: `
         
          <div style="display:flex; flex-direction:column">
            <span>${
              this.contratacao.usuario_logado
                ? "Verifique se seus dados estão corretos e informe o <b>e-mail financeiro</b> onde será enviado os <b>dados de cobrança da sua conta</b>"
                : "Informe o seu <b>CPF</b> e clique no icone ✔️ ou no botão avançar."
            }</span>
            <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
          </div>
        
        `,

      attachTo: {
        on: "top",
      },

      classes: "example-step-extra-class",

      buttons: [
        {
          text: "OK",
          action: this.tour.next,
        },
      ],
    });

    this.tour.addStep({
      id: "contratacao-cadastro-deseja-cadastrar-informacoes",

      text: `
            <div style="display:flex; flex-direction:column">
              <span>Escolha se você deseja <b>informar agora</b> os dados adicionais da sua empresa ou <b>após a finalização</b> do cadastro da conta</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
        `,

      attachTo: {
        on: "top",
      },

      classes: "example-step-extra-class",

      buttons: [
        {
          text: "OK",
          action: this.tour.hide,
        },
      ],
    });

    this.tour.start();
  }

  initDadosDosResponsaveis() {
    this.tourDadosDosResponsaveis = new Shepherd.Tour({
      useModalOverlay: true,
      exitOnEsc: true,
      defaultStepOptions: {
        classes: "shadow-md bg-purple-dark",
        scrollTo: true,
      },
    });

    this.tourDadosDosResponsaveis.addStep({
      id: "contratacao-cadastro-dados-responsavel",

      text: `
            <div style="display:flex; flex-direction:column">
              <span>${
                this.contratacao.usuario_logado
                  ? "Verifique se seus dados estão corretos e informe o <b>e-mail financeiro</b> onde será enviado os <b>dados de cobrança da sua conta</b>"
                  : "Informe o seu <b>CPF</b> e clique no icone ✔️ ou no botão avançar."
              }</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
        `,

      attachTo: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        element: document.getElementById(
          "contratacao-cadastro-dados-responsavel"
        )!,
        on: "top",
      },

      classes: "example-step-extra-class",

      buttons: [
        {
          text: "OK",
          action: this.tourDadosDosResponsaveis.next,
        },
      ],
    });

    if (this.contratacao.usuario_logado)
      this.tourDadosDosResponsaveis.addStep({
        id: "contratacao-cadastro-deseja-cadastrar-informacoes",

        text: `
           <div style="display:flex; flex-direction:column">
              <span>Escolha se você deseja <b>informar agora</b> os dados adicionais da sua empresa ou <b>após a finalização</b> do cadastro da conta</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
          `,

        attachTo: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          element: document.getElementById(
            "contratacao-cadastro-deseja-cadastrar-informacoes"
          )!,

          on: "top",
        },

        classes: "example-step-extra-class",

        buttons: [
          {
            text: "OK",
            action: this.tourDadosDosResponsaveis.hide,
          },
        ],
      });

    this.tourDadosDosResponsaveis.start();
  }

  initTourInformacoesAdicionais() {
    setTimeout(() => {
      this.tourInformacoesAdicionais = new Shepherd.Tour({
        useModalOverlay: true,
        exitOnEsc: true,
        defaultStepOptions: {
          classes: "shadow-md bg-purple-dark",
          scrollTo: true,
        },
      });

      if (this.contratacao.cadastrou_informacoes_adicionais) {
        this.tourInformacoesAdicionais.addStep({
          id: "contratacao-cadastro-informacoes-adicionais",

          text: `
            <div style="display:flex; flex-direction:column">
              <span>Informe os dados adicionais da sua empresa.</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
            `,

          attachTo: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            element: document.getElementById(
              "contratacao-cadastro-informacoes-adicionais"
            )!,
            on: "bottom",
          },

          classes: "example-step-extra-class",

          buttons: [
            {
              text: "OK",
              action: this.tourInformacoesAdicionais.next,
            },
          ],
        });
      }

      if (
        this.contratacao.plano_selecionado !== PlanosEnum.GRATIS &&
        this.contratacao.plano_selecionado !== PlanosEnum.TRIAL
      ) {
        this.tourInformacoesAdicionais.addStep({
          id: "contratacao-cadastro-tipo-pagamento",

          text: `
            <div style="display:flex; flex-direction:column">
              <span>Escolha a <b>forma de pagamento</b> mais adequada para sua empresa.</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
            `,

          attachTo: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            element: document.getElementById(
              "contratacao-cadastro-tipo-pagamento"
            )!,
            on: "bottom",
          },

          classes: "example-step-extra-class",

          buttons: [
            {
              text: "OK",
              action: this.tourInformacoesAdicionais.next,
            },
          ],
        });
      }

      this.tourInformacoesAdicionais.addStep({
        id: "contratacao-cadastro-pagamento",

        text: `
            <div style="display:flex; flex-direction:column">
              <span>Confime se as suas informações estão corretas e finalize a criação da sua conta.</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
          `,

        attachTo: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          element: document.getElementById("contratacao-cadastro-pagamento")!,
          on: "bottom",
        },

        classes: "example-step-extra-class",

        buttons: [
          {
            text: "OK",
            action: this.tourInformacoesAdicionais.hide,
          },
        ],
      });

      this.tourInformacoesAdicionais.start();
    }, 500);
  }

  initTourFrete() {
    this.tourFrete = new Shepherd.Tour({
      useModalOverlay: true,
      exitOnEsc: true,
      defaultStepOptions: {
        classes: "shadow-md bg-purple-dark",
        scrollTo: true,
      },
    });

    this.tourFrete.addStep({
      id: "contratacao-cadastro-frete-informacoes",

      text: `
            <div style="display:flex; flex-direction:column">
              <span>Informe o <b>código de referência</b>, o qual representa seu <b>código interno de controle</b>, o tipo da sua operação, as suas carrocerias, e o peso toral da carga.</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
        `,

      attachTo: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        element: document.getElementById(
          "contratacao-cadastro-frete-informacoes"
        )!,
        on: "bottom",
      },

      classes: "example-step-extra-class",

      buttons: [
        {
          text: "OK",
          action: this.tourFrete.hide,
        },
      ],
    });

    this.tourFrete.addStep({
      id: "contratacao-cadastro-frete-local",

      text: `
            <div style="display:flex; flex-direction:column">
              <span>Preencha o local de <b>carregamento</b>, <b>descarregamento</b> e tambem informe os dados do cliente.</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
        `,

      attachTo: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        element: document.getElementById("contratacao-cadastro-frete-local")!,
        on: "bottom",
      },

      classes: "example-step-extra-class",

      buttons: [
        {
          text: "OK",
          action: this.tourFrete.hide,
        },
      ],
    });

    this.tourFrete.addStep({
      id: "contratacao-cadastro-frete-produtos",

      text: `
            <div style="display:flex; flex-direction:column">
              <span>Informe a lista de produtos do frete.</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
        `,

      attachTo: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        element: document.getElementById(
          "contratacao-cadastro-frete-produtos"
        )!,
        on: "bottom",
      },

      classes: "example-step-extra-class",

      buttons: [
        {
          text: "OK",
          action: this.tourFrete.hide,
        },
      ],
    });

    this.tourFrete.addStep({
      id: "contratacao-cadastro-frete-finalizacao",

      text: `
            <div style="display:flex; flex-direction:column">
              <span>Selecione se o valor do frete será pago da <b>viagem total</b> ou por <b>tonelada carregada</b>. Informe se o frete ofertado para <b>transportadoras e/ou motoristas</b> e o seu respectivo valor.</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
        `,

      attachTo: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        element: document.getElementById(
          "contratacao-cadastro-frete-finalizacao"
        )!,
        on: "bottom",
      },

      classes: "example-step-extra-class",

      buttons: [
        {
          text: "OK",
          action: this.tourFrete.hide,
        },
      ],
    });

    this.tourFrete.start();
  }

  initTourCotacao() {
    this.tourCotacao = new Shepherd.Tour({
      useModalOverlay: true,
      exitOnEsc: true,
      defaultStepOptions: {
        classes: "shadow-md bg-purple-dark",
        scrollTo: true,
      },
    });

    this.tourCotacao.addStep({
      id: "contratacao-cadastro-cotacao-informacoes",

      text: `
            <div style="display:flex; flex-direction:column">
              <span>Informe se a cotação será tomada de preço, busca de transportadora ou leilão reverso e qual sera o tipo da precificação. Informe o código de referência, o qual representa seu código interno de controle, as suas carrocerias, e o peso total da carga.</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
        `,

      attachTo: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        element: document.getElementById(
          "contratacao-cadastro-cotacao-informacoes"
        )!,
        on: "bottom",
      },

      classes: "example-step-extra-class",

      buttons: [
        {
          text: "OK",
          action: this.tourCotacao.hide,
        },
      ],
    });

    this.tourCotacao.addStep({
      id: "contratacao-cadastro-cotacao-local",

      text: `
            <div style="display:flex; flex-direction:column">
              <span>Informe o <b>CNPJ</b> da sua empresa e clique no icone ✔️ ou no botão avançar.</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
        `,

      attachTo: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        element: document.getElementById("contratacao-cadastro-cotacao-local")!,
        on: "bottom",
      },

      classes: "example-step-extra-class",

      buttons: [
        {
          text: "OK",
          action: this.tourCotacao.hide,
        },
      ],
    });

    this.tourCotacao.addStep({
      id: "contratacao-cadastro-cotacao-produtos",

      text: `
            <div style="display:flex; flex-direction:column">
              <span>Informe o <b>CNPJ</b> da sua empresa e clique no icone ✔️ ou no botão avançar.</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
        `,

      attachTo: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        element: document.getElementById(
          "contratacao-cadastro-cotacao-produtos"
        )!,
        on: "bottom",
      },

      classes: "example-step-extra-class",

      buttons: [
        {
          text: "OK",
          action: this.tourCotacao.hide,
        },
      ],
    });

    this.tourCotacao.addStep({
      id: "contratacao-cadastro-cotacao-finalizacao",

      text: `
            <div style="display:flex; flex-direction:column">
              <span>Informe o <b>CNPJ</b> da sua empresa e clique no icone ✔️ ou no botão avançar.</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
        `,

      attachTo: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        element: document.getElementById(
          "contratacao-cadastro-cotacao-finalizacao"
        )!,
        on: "bottom",
      },

      classes: "example-step-extra-class",

      buttons: [
        {
          text: "OK",
          action: this.tourCotacao.hide,
        },
      ],
    });

    this.tourCotacao.start();
  }

  async buscarInformacoesDoUsuario() {
    try {
      const config = LocalStorageProvider.retrieve<ConfigDTO>(
        LocalStorageEnum.CONFIG_KEY
      );
      this.$store.dispatch("contratacaostate/setResponsavelCpf", config.cpf);

      if (!config.cpf) return;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const response = await new UsuarioAPI().buscarInformacoesPublicasUsuario(
        config.cpf!
      );
      this.$store.dispatch(
        "contratacaostate/setInformacoesPublicasUsuario",
        response.data
      );
    } catch (error) {
      this.realizandoLogin = false;
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true);
      this.$store.dispatch(
        "appstate/setSnackbarErrorMessage",
        ErrorHandling.tratarMensagemDeError(error)
      );
    }
  }

  @Watch("contratacao.abasCadastro")
  onChangeAbasCadastro() {
    setTimeout(() => {
      const elements = document.querySelectorAll(
        '[id^="contratacao-cadastro"]'
      );
      const id = elements[elements.length - 1];
      const element = document.getElementById(id.id);
      if (element)
        element.scrollIntoView({ block: "start", behavior: "smooth" });
    }, 200);
  }

  @Watch("contratacao.finalizado_cadastro_empresa")
  onChangeFinalizouCadastroEmpresa(newValue: boolean, oldValue: boolean) {
    if (newValue === oldValue) return;

    if (newValue) {
      if (this.contratacao.plano_selecionado === PlanosEnum.TRIAL) {
        this.adicionarNovaAba("DESEJA_CADASTRAR_FRETE_COTACAO");
        this.tourAddStep(
          "contratacao-cadastro-deseja-cadastrar-frete-cotacao",
          "Escolha uma opção para prosseguir o cadastro.",
          "hide"
        );
        setTimeout(() => {
          this.tour.next();
        }, 500);
      } else {
        this.adicionarNovaAba("DADOS_RESPONSAVEL");
        this.tourAddStep(
          "contratacao-cadastro-dados-responsavel",
          `${
            this.contratacao.usuario_logado
              ? "Verifique se seus dados estão corretos e informe o <b>e-mail financeiro</b> onde será enviado os <b>dados de cobrança da sua conta</b>"
              : "Informe o seu <b>CPF</b> e clique no icone ✔️ ou no botão avançar."
          }`,
          `${this.contratacao.usuario_logado ? "next" : "hide"}`
        );
        setTimeout(() => {
          this.tour.next();
        }, 500);

        if (this.contratacao.usuario_logado) {
          this.adicionarNovaAba("DESEJA_CADASTRAR_INFORMACOES_ADICIONAIS");
          this.tourAddStep(
            "contratacao-cadastro-deseja-cadastrar-informacoes",
            "Escolha se você deseja <b>informar agora</b> os dados adicionais da sua empresa ou <b>após a finalização</b> do cadastro da conta",
            "hide"
          );
        }
      }
    }

    if (!newValue) this.removerAba("DADOS_RESPONSAVEL");
  }

  @Watch("contratacao.finalizado_cadastro_usuario")
  onChangeFinalizouCadastroUsuario(newValue: boolean, oldValue: boolean) {
    if (newValue === oldValue) return;
    if (newValue) {
      if (
        this.contratacao.usuario_logado &&
        this.contratacao.situacao_cpf_usuario === "entrar"
      ) {
        this.adicionarNovaAba("DESEJA_CADASTRAR_INFORMACOES_ADICIONAIS");
      } else if (
        !this.contratacao.usuario_logado &&
        this.contratacao.situacao_cpf_usuario === "cadastrar"
      ) {
        this.adicionarNovaAba("CADASTRO_CONTA_USUARIO");
      } else if (this.contratacao.situacao_cpf_usuario === "ativar") {
        this.adicionarNovaAba("REENVIAR_CONTA_USUARIO");
      }
    }
    if (!newValue) this.removerAbaPeloIndex(2);
  }

  informareiMaisTardeInformacoesAdicionais() {
    this.$store.dispatch(
      "contratacaostate/setCadastrou_informacoes_adicionais",
      false
    );
    this.removerAba("INFORMACOES_ADICIONAIS");

    if (
      this.contratacao.plano_selecionado !== PlanosEnum.GRATIS &&
      this.contratacao.plano_selecionado !== PlanosEnum.TRIAL
    ) {
      this.adicionarNovaAba("INFORMAR_TIPO_PAGAMENTO");
    }
    this.adicionarNovaAba("PAGAMENTO");
    this.adicionarNovaAba("TERMO_DE_USO");

    this.finalizarTodosOsTours();

    this.initTourInformacoesAdicionais();
  }

  informareiInformacoesAdicionais() {
    this.$store.dispatch(
      "contratacaostate/setCadastrou_informacoes_adicionais",
      true
    );
    this.removerAba("INFORMAR_TIPO_PAGAMENTO");
    this.adicionarNovaAba("INFORMACOES_ADICIONAIS");
    this.adicionarNovaAba("PAGAMENTO");
    this.adicionarNovaAba("TERMO_DE_USO");

    this.finalizarTodosOsTours();

    setTimeout(() => {
      this.initTourInformacoesAdicionais();
    }, 600);
  }

  cadastrarFrete() {
    this.$store.dispatch("contratacaostate/setcadastro_frete_ativo", true);
    this.$store.dispatch("contratacaostate/setcadastro_cotacao_ativo", false);
    this.removerAbasAPartirDaAbaInformada("DESEJA_CADASTRAR_FRETE_COTACAO");
    this.adicionarNovaAba("INFORMACOES_FRETE");
    this.verificarInformacoesDoFrete();

    this.finalizarTodosOsTours();

    setTimeout(() => {
      this.initTourFrete();
    }, 500);
  }

  cadastrarCotacao() {
    this.$store.dispatch("contratacaostate/setcadastro_cotacao_ativo", true);
    this.$store.dispatch("contratacaostate/setcadastro_frete_ativo", false);
    this.removerAbasAPartirDaAbaInformada("DESEJA_CADASTRAR_FRETE_COTACAO");
    this.adicionarNovaAba("INFORMACOES_COTACAO");
    this.verificarInformacoesDaCotacao();

    this.finalizarTodosOsTours();

    setTimeout(() => {
      this.initTourCotacao();
    }, 500);
  }

  naoCadastrarFreteCotacao() {
    this.$store.dispatch("contratacaostate/setcadastro_cotacao_ativo", false);
    this.$store.dispatch("contratacaostate/setcadastro_frete_ativo", false);

    this.removerAbasAPartirDaAbaInformada("DESEJA_CADASTRAR_FRETE_COTACAO");
    this.adicionarNovaAba("DADOS_RESPONSAVEL");

    if (this.contratacao.usuario_logado) {
      this.adicionarNovaAba("DESEJA_CADASTRAR_INFORMACOES_ADICIONAIS");
    }

    this.finalizarTodosOsTours();

    setTimeout(() => {
      this.initDadosDosResponsaveis();
    }, 500);
  }

  naoCadastrarFrete() {
    this.removerAba("INFORMAR_TIPO_PAGAMENTO");
    this.adicionarNovaAba("INFORMACOES_ADICIONAIS");
  }

  @Watch("freteCompleto", { deep: true })
  onChangeFrete() {
    this.verificarInformacoesDoFrete();
  }

  localFreteFinalizado = false;
  produtoFreteFinalizado = false;
  informacoeFinaisFreteFinalizado = false;
  dadosDoResponsavelFrete = false;
  desejaCadastrarInformacoesAdicionaisFrete = false;

  verificarInformacoesDoFrete() {
    if (
      FreteHelper.verificarInformacoesFrete(this.freteCompleto) &&
      !this.localFreteFinalizado
    ) {
      this.adicionarNovaAba("LOCAL_FRETE");

      this.tourAddStepFrete(
        "contratacao-cadastro-frete-local",
        "Preencha o local de <b>carregamento</b>, <b>descarregamento</b> e tambem informe os dados do cliente.",
        "hide"
      );
      setTimeout(() => {
        this.tourFrete.next();
      }, 500);
      this.localFreteFinalizado = true;
    }

    if (
      FreteHelper.verificarInformacoesLocalFrete(this.freteCompleto) &&
      !this.produtoFreteFinalizado
    ) {
      this.adicionarNovaAba("PRODUTOS_FRETE");

      this.tourAddStepFrete(
        "contratacao-cadastro-frete-produtos",
        "Informe a lista de produtos do frete.",
        "hide"
      );
      setTimeout(() => {
        this.tourFrete.next();
      }, 500);
      this.produtoFreteFinalizado = true;
    }

    if (
      FreteHelper.verificarProdutosFrete(this.freteCompleto.Produtos) &&
      !this.informacoeFinaisFreteFinalizado
    ) {
      this.adicionarNovaAba("FINALIZACAO_FRETE");

      this.tourAddStepFrete(
        "contratacao-cadastro-frete-finalizacao",
        "Selecione se o valor do frete será pago da <b>viagem total</b> ou por <b>tonelada carregada</b>. Informe se o frete ofertado para <b>transportadoras e/ou motoristas</b> e o seu respectivo valor.",
        "hide"
      );
      setTimeout(() => {
        this.tourFrete.next();
      }, 500);
      this.informacoeFinaisFreteFinalizado = true;
    }

    if (
      FreteHelper.verificarInformacoesFinais(this.freteCompleto) &&
      !this.dadosDoResponsavelFrete
    ) {
      this.adicionarNovaAba("DADOS_RESPONSAVEL");

      if (this.contratacao.usuario_logado) {
        this.adicionarNovaAba("DESEJA_CADASTRAR_INFORMACOES_ADICIONAIS");
      }
      this.dadosDoResponsavelFrete = true;
      this.finalizarTodosOsTours();
      setTimeout(() => {
        this.initDadosDosResponsaveis();
      }, 500);
    }
  }

  @Watch("cotacaoCompleta", { deep: true })
  onChangeCotacao() {
    this.verificarInformacoesDaCotacao();
  }

  localCotacaoFinalizado = false;
  produtosCotacaoFinalizado = false;
  finalizacaoCotacaoFinalizado = false;
  dadosDoResponsavelCotacao = false;
  desejaCadastrarInformacoesAdicionaisCotacao = false;

  verificarInformacoesDaCotacao() {
    if (
      CotacaoHelper.verificarInformacoesCotacao(this.cotacaoCompleta) &&
      !this.localCotacaoFinalizado
    ) {
      this.adicionarNovaAba("LOCAL_COTACAO");
      this.tourAddStepCotacao(
        "contratacao-cadastro-cotacao-local",
        "Preencha o local de carregamento e descarregamento.",
        "hide"
      );
      setTimeout(() => {
        this.tourCotacao.next();
      }, 500);
      this.localCotacaoFinalizado = true;
    }

    if (
      CotacaoHelper.verificarInformacoesLocalCotacao(this.cotacaoCompleta) &&
      !this.produtosCotacaoFinalizado
    ) {
      this.adicionarNovaAba("PRODUTOS_COTACAO");
      this.tourAddStepCotacao(
        "contratacao-cadastro-cotacao-produtos",
        "Informe a lista de produtos do frete.",
        "hide"
      );
      setTimeout(() => {
        this.tourCotacao.next();
      }, 500);
      this.produtosCotacaoFinalizado = true;
    }

    if (
      CotacaoHelper.verificarInformacoesProdutos(
        this.cotacaoCompleta.produtos
      ) &&
      !this.finalizacaoCotacaoFinalizado
    ) {
      this.adicionarNovaAba("FINALIZAR_COTACAO");
      this.tourAddStepCotacao(
        "contratacao-cadastro-cotacao-finalizacao",
        "Selecione se o valor do frete será pago da viagem total ou por tonelada carregada, a observação da cotação, o prazo e a vigência. Informe tambem a lista das transportadoras convidadas.",
        "hide"
      );
      setTimeout(() => {
        this.tourCotacao.next();
      }, 500);
      this.finalizacaoCotacaoFinalizado = true;
    }

    if (
      CotacaoHelper.verificarFinalizacaoDaCotacao(this.cotacaoCompleta) &&
      !this.dadosDoResponsavelCotacao
    ) {
      this.adicionarNovaAba("DADOS_RESPONSAVEL");

      if (this.contratacao.usuario_logado) {
        this.adicionarNovaAba("DESEJA_CADASTRAR_INFORMACOES_ADICIONAIS");
      }

      this.dadosDoResponsavelCotacao = true;
      this.finalizarTodosOsTours();

      setTimeout(() => {
        this.initDadosDosResponsaveis();
      }, 500);
    }
  }

  removerAbaPeloIndex(index: number) {
    this.$store.dispatch("contratacaostate/rremoverAbaPeloIndex", index);
  }

  removerAba(aba: string) {
    this.$store.dispatch("contratacaostate/removerAba", aba);
  }

  adicionarNovaAba(aba: string) {
    this.$store.dispatch("contratacaostate/adicionarNovaAba", aba);
  }

  removerAbasAPartirDaAbaInformada(aba: string) {
    this.$store.dispatch(
      "contratacaostate/removerAbasAPartirDaAbaInformada",
      aba
    );
  }

  @Watch("contratacao.usuario_logado")
  onChangeUsuarioLogado(newValue: boolean) {
    if (!newValue) this.removerAbasAPartirDaAbaInformada("DADOS_RESPONSAVEL");
  }

  tourAddStep(
    elementId: string,
    message: string,
    typeAction = "next",
    buttonText = "OK"
  ) {
    setTimeout(() => {
      this.tour.getById(elementId)?.updateStepOptions({
        id: elementId,

        text: `
            <div style="display:flex; flex-direction:column">
              <span>${message}</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
        `,

        attachTo: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          element: document.getElementById(elementId)!,
          on: "bottom",
        },

        classes: "example-step-extra-class",

        buttons: [
          {
            text: buttonText,
            action: typeAction === "hide" ? this.tour.hide : this.tour.next,
          },
        ],
      });
    }, 500);
  }

  tourAddStepFrete(
    elementId: string,
    message: string,
    typeAction = "next",
    buttonText = "OK"
  ) {
    setTimeout(() => {
      this.tourFrete.getById(elementId)?.updateStepOptions({
        id: elementId,

        text: `
            <div style="display:flex; flex-direction:column">
              <span>${message}</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
        `,

        attachTo: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          element: document.getElementById(elementId)!,
          on: "bottom",
        },

        classes: "example-step-extra-class",

        buttons: [
          {
            text: buttonText,
            action:
              typeAction === "hide" ? this.tourFrete.hide : this.tourFrete.next,
          },
        ],
      });
    }, 500);
  }

  tourAddStepCotacao(
    elementId: string,
    message: string,
    typeAction = "next",
    buttonText = "OK"
  ) {
    setTimeout(() => {
      this.tourCotacao.getById(elementId)?.updateStepOptions({
        id: elementId,

        text: `
            <div style="display:flex; flex-direction:column">
              <span>${message}</span>
              <img src="img/ojo-personagem-03.png" width="120" height="120" alt="OJO" style="margin:0 auto; margin-top: 10px">
            </div>
        `,

        attachTo: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          element: document.getElementById(elementId)!,
          on: "bottom",
        },

        classes: "example-step-extra-class",

        buttons: [
          {
            text: buttonText,
            action:
              typeAction === "hide"
                ? this.tourCotacao.hide
                : this.tourCotacao.next,
          },
        ],
      });
    }, 500);
  }

  naoRecebiCodigoAtivacao() {
    this.removerAba("ATIVAR_CONTA_USUARIO");
    this.adicionarNovaAba("REENVIAR_CONTA_USUARIO");
  }

  async reenviarCodigoAtivacao() {
    try {
      if (!this.contratacao.responsavel_cpf)
        throw new BusinessError("Informe o cpf");
      if (!this.celularVerificacao)
        throw new BusinessError("Informe o celular");
      const cpf = MaskHelper.cleanMaskCPF(this.contratacao.responsavel_cpf);
      const celular = MaskHelper.cleanMaskTelefone(this.celularVerificacao);
      await new UsuarioAPI().reenviarCodigo(cpf, celular);
      this.removerAba("REENVIAR_CONTA_USUARIO");
      this.adicionarNovaAba("ATIVAR_CONTA_USUARIO");
    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true);
      this.$store.dispatch(
        "appstate/setSnackbarErrorMessage",
        ErrorHandling.tratarMensagemDeError(error)
      );
    }
  }

  async cadastrarUsuario() {
    const indication = {
      codigo_indicacao: String(sessionStorage.getItem("validCode")),
      tipo_conta: String(sessionStorage.getItem("tipo")),
      codigo_identificador_celular: "",
      cpf_cnpj: String(sessionStorage.getItem("cnpj")),
    };

    try {
      this.usuario.cpf = this.contratacao.responsavel_cpf;
      this.usuario.nome = this.contratacao.responsavel_cadastro_nova_conta_nome;
      this.usuario.sobrenome =
        this.contratacao.responsavel_cadastro_nova_conta_sobrenome;
      CadastroUsuarioBO.validateCadastroUsuario(
        this.usuario,
        this.confirmarSenha
      );
      const usuario = CadastroUsuarioFactory.buildCadastroUsuario(this.usuario);
      await new UsuarioAPI().cadastrarUsuario(usuario);
      axios
        .post(
          `${process.env.VUE_APP_API_URL_INDICACAO}confirmar-indicacao`,
          indication
        )
        .then((data) => {
          console.log(data);
        });
      this.removerAba("CADASTRO_CONTA_USUARIO");
      this.adicionarNovaAba("ATIVAR_CONTA_USUARIO");
    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true);
      this.$store.dispatch(
        "appstate/setSnackbarErrorMessage",
        ErrorHandling.tratarMensagemDeError(error)
      );
    }
  }

  async ativacaoConta() {
    try {
      LocalStorageProvider.remove(LocalStorageEnum.CONFIG_KEY);
      const cpf = MaskHelper.cleanMaskCPF(
        this.usuario.cpf || this.contratacao.responsavel_cpf
      );
      await new UsuarioAPI().ativarUsuario(cpf, this.codigo_ativacao);

      if (!this.usuario.senha) {
        this.removerAba("ATIVAR_CONTA_USUARIO");
        return;
      }

      const response = await new AutenticacaoAPI().autenticarUsuario(
        cpf,
        md5(this.usuario.senha)
      );
      const token = response.data.token;
      if (!token) return;
      const decodedToken = jwt_decode(token);
      const decodedTokenObject = decodedToken as JWTDTO;

      LocalStorageProvider.store<ConfigDTO>(
        {
          jwt: token,
          id_usuario: decodedTokenObject.id_usuario,
          cpf: this.usuario.cpf,
        },
        LocalStorageEnum.CONFIG_KEY
      );
      this.$store.dispatch(
        "contratacaostate/setid_usuario",
        Number(decodedTokenObject.id_usuario)
      );
      this.$store.dispatch("contratacaostate/setusuario_logado", true);

      this.removerAba("ATIVAR_CONTA_USUARIO");
      this.adicionarNovaAba("DESEJA_CADASTRAR_INFORMACOES_ADICIONAIS");
      this.buscarInformacoesDoUsuario();
    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true);
      this.$store.dispatch(
        "appstate/setSnackbarErrorMessage",
        ErrorHandling.tratarMensagemDeError(error)
      );
    }
  }

  finalizarTodosOsTours() {
    if (this.tour && this.tour.isActive()) this.tour.cancel();

    if (
      this.tourInformacoesAdicionais &&
      this.tourInformacoesAdicionais.isActive()
    )
      this.tourInformacoesAdicionais.cancel();

    if (
      this.tourDadosDosResponsaveis &&
      this.tourDadosDosResponsaveis.isActive()
    )
      this.tourDadosDosResponsaveis.cancel();

    if (this.tourFrete && this.tourFrete.isActive()) this.tourFrete.cancel();

    if (this.tourCotacao && this.tourCotacao.isActive())
      this.tourCotacao.cancel();
  }

  destroyed() {
    this.$store.dispatch("contratacaostate/limparListaDeAbas");
    this.finalizarTodosOsTours();
  }
}
