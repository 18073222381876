import HttpProvider from "@/providers/HttpProvider"
import { AxiosResponse } from "axios"
import CadastroUsuarioDTO, { UsuarioCadastradoDTO } from "./CadastroUsuarioDTO"
import UsuarioInformacoesPublicasDTO from "./UsuarioInformacoesPublicasDTO"
import UsuarioValidarSituacaoDTO from "./UsuarioValidarSituacaoDTO"

class UsuarioAPI {

    private httpProvider: HttpProvider

    constructor() {
        this.httpProvider = new HttpProvider()
    }

    public cadastrarUsuario(usuario: CadastroUsuarioDTO): Promise<AxiosResponse<UsuarioCadastradoDTO>> {
        return this.httpProvider.axiosInstanceBase.post(`/usuario/v2`, usuario)
    }

    public buscarUsuarioPlataforma(idUsuario: number) {
        return this.httpProvider.axiosInstanceBase.get(`/usuario/v2/usuario-plataforma/${idUsuario}`)
    }

    public ativarUsuario(cpf: string, codigo: string) {
        return this.httpProvider.axiosInstanceBase.post(`/usuario/v2/ativar/${cpf}/${codigo}`, {})
    }

    public validarSituacaoCpf(cpf: string): Promise<AxiosResponse<UsuarioValidarSituacaoDTO>> {
        return this.httpProvider.axiosInstanceBase.get(`/usuario/v2/validar-situacao-cpf/${cpf}`)
    }

    public buscarInformacoesPublicasUsuario(cpf: string): Promise<AxiosResponse<UsuarioInformacoesPublicasDTO>> {
        return this.httpProvider.axiosInstanceBase.get(`/usuario/v2/cpf-aberto/${cpf}`)
    }

    public reenviarCodigo(cpf: string, celular: string) {
        return this.httpProvider.axiosInstanceBase.post(`/usuario/v2/reenviar-codigo-ativacao/${cpf}/${celular}`)
    }

}


export default UsuarioAPI
