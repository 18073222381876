export enum NavegacaoContratacao {

    ContratacaoDadosDaEmpresa = 'CONTRATACAO_DADOS_DA_EMPRESA',
    ContratacaoDadosResponsavel = 'CONTRATACAO_DADOS_RESPONSAVEL',
    ContratacaoInformacoesAdicionais = 'CONTRATACAO_INFORMACOES_ADICIONAIS',
    ContratacaoDesejaCadastrarFreteCotacao = 'CONTRATACAO_DESEJA_CADASTRAR_FRETE_COTACAO',
    ContratacaoPagamento = 'CONTRATACAO_PAGAMENTO',

    FreteInformacoesIniciais = 'FRETE_INFORMACOES_INICIAIS',
    FreteLocalDoFrete = 'FRETE_LOCAL_DO_FRETE',
    FreteProduto = 'FRETE_PRODUTO',
    FreteFinalizacao = 'FRETE_FINALIZACAO',

    CotacaoInformacoesIniciais = 'COTACAO_INFORMACOES_INICIAIS',
    CotacaoLocalDoFrete = 'COTACAO_LOCAL_DO_FRETE',
    CotacaoProduto = 'COTACAO_PRODUTO',
    CotacaoFinalizacao = 'COTACAO_FINALIZACAO',
}

export enum NavegacaoContratacaoTipoCadastro {
    cotacao = 'COTACAO',
    frete = 'FRETE',
    contratacao = 'CONTRATACAO'
}