import HttpProvider from "@/providers/HttpProvider"
import { AxiosResponse } from "axios"
import { PlanoSelecionadoDTO } from "./PlanosDTO"

class PlanosAPI {
  private httpProvider: HttpProvider

  constructor() {
    this.httpProvider = new HttpProvider()
  }

  public searchPlanById(
    planId: number
  ): Promise<AxiosResponse<Array<PlanoSelecionadoDTO>>> {
    return this.httpProvider.axiosInstanceBase.get(`/plano/v2/${planId}`)
  }
}

export default PlanosAPI
