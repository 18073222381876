
















































































import {
  NavegacaoContratacao,
  NavegacaoContratacaoTipoCadastro,
} from "@/domains/contratacao/ContratacaoEnum"
import { FreteCarroceriaDTO, FreteCompletoDTO } from "@/domains/frete/FreteDTO"
import { Vue, Component } from "vue-property-decorator"
import { mapActions, mapGetters } from "vuex"
import CarroceriaAPI from "@/domains/carroceria/CarroceriaAPI"
import CarroceriaHelper from "@/domains/carroceria/CarroceriaHelper"
import { SortDTO } from "@/domains/application/SortDTO"
import ErrorHandling from "@/domains/application/ErrorHandling"
import FreteBO from "@/domains/frete/FreteBO"
import FreteHelper from "@/domains/frete/FreteHelper"
import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO"

@Component({
  computed: {
    ...mapGetters({
      freteCompleto: "fretestate/freteCompleto",
      contratacao: 'contratacaostate/contratacao',
    }),
  },
  methods: {
    ...mapActions("fretestate", [
      "setCodigo_referencia",
      "setTipo_operacao",
      "setPeso",
    ]),
  },
})

export default class InformacoesFrete extends Vue {
  contratacao!: ContratacaoDTO
  navegacaoContratacao = NavegacaoContratacao;
  tipoCadastro = NavegacaoContratacaoTipoCadastro;
  freteCompleto!: FreteCompletoDTO
  carrocerias: FreteCarroceriaDTO[] = []
  carregandoCarrocerias = false
  skip = 0
  take = 100
  sort: SortDTO = {
    selector: "descricao",
    desc: false
  }
  filters = { ...CarroceriaHelper.getFilter() }


  mounted() {
    this.buscarCarrocerias()
  }

  buscarCarrocerias() {
    this.carregandoCarrocerias = true
    new CarroceriaAPI().buscarCarrocerias(this.skip, this.take, this.sort, this.filters).then(r => {
      const carrocerias = r.data
      carrocerias.forEach(element => {
        this.carrocerias.push({ descricao_carroceria: element.descricao })
      })
      this.carregandoCarrocerias = false
    }).catch(e => {
      this.carregandoCarrocerias = false
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
    })
  }

  verificarSePodeProsseguir() {
    try {
      FreteBO.validarInformacoesDoFrete(this.freteCompleto)
      if (FreteHelper.verificarInformacoesFrete(this.freteCompleto)) this.$store.dispatch("contratacaostate/adicionarNovaAba", 'LOCAL_FRETE')
    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(error))
    }
  }

  get peso_exato() {
    return this.freteCompleto.Frete.peso_exato
  }

  set peso_exato(peso_exato: string) {
    this.$store.dispatch("fretestate/setPeso_exato", peso_exato)
  }

  get carrocerias_selecionadas() {
    return this.freteCompleto.Carrocerias
  }

  set carrocerias_selecionadas(carrocerias: FreteCarroceriaDTO[]) {
    this.$store.dispatch("fretestate/setCarrocerias", carrocerias)
  }

  get tipo_operacao() {
    return this.freteCompleto.Frete.tipo_operacao
  }

  set tipo_operacao(tipoOperacao: string) {
    this.$store.dispatch("fretestate/setTipo_operacao", tipoOperacao)
  }

}
