import BusinessError from "../application/BusinessError"
import CadastroUsuarioDTO from "./CadastroUsuarioDTO"

export default class CadastroUsuarioBO {

    public static validateCadastroUsuario(usuario: CadastroUsuarioDTO, confirmarSenha: string) {
        if ([null, undefined, '', NaN, 0, {}, 'null', 'undefined'].includes(usuario)) throw new BusinessError('Erro interno tente novamente mais tarde, ou entre em contado com o suporte OJO')
        if ([null, undefined, '', NaN, 0].includes(usuario.nome)) throw new BusinessError('Infome o seu nome')
        if ([null, undefined, '', NaN, 0].includes(usuario.sobrenome)) throw new BusinessError('Infome o seu sobrenome')
        if ([null, undefined, '', NaN, 0].includes(usuario.email)) throw new BusinessError('Infome o seu email')
        if ([null, undefined, '', NaN, 0].includes(usuario.cpf)) throw new BusinessError('Infome o seu cpf')
        if ([null, undefined, '', NaN, 0].includes(usuario.senha)) throw new BusinessError('Infome sua senha')
        if (usuario.senha !== confirmarSenha || confirmarSenha !== usuario.senha) throw new BusinessError('Os campos senha e confirmar senha devem ser iguais')
    }

}