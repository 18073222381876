/* eslint-disable @typescript-eslint/no-non-null-assertion */
import DateTimeProvider from "@/providers/DateTimeProvider"
import { MaskHelper } from "../application/MaskHelper"
import ContratacaoDTO from "../contratacao/ContratacaoDTO"
import CotacaoCompletaDTO from "../cotacao/CotacaoCompletaDTO"
import { FreteCompletoDTO } from "../frete/FreteDTO"
import { PlanosEnum } from "../planos/PlanosEnum"
import { ContaCompletaDTO, ContaTrialDTO } from "./ContaDTO"

export default class ContaFactory {

  public static buildAccount(conta: ContratacaoDTO): ContaCompletaDTO {
    let segmentos = []
    let tipoCargas = []

    if (conta.segmentos.includes('Outros')) {
      segmentos = conta.segmentos
      segmentos.push(conta.outro_segmento)
    } else {
      segmentos = conta.segmentos
    }

    if (conta.tipo_cargas.includes('Outros')) {
      tipoCargas = conta.tipo_cargas
      tipoCargas.push(conta.outros_tipos_cargas)
    } else {
      tipoCargas = conta.tipo_cargas
    }


    return {
      id_usuario: conta.id_usuario,
      id_plano: conta.plano_selecionado_completo.id_plano,
      tipo: conta.tipo,
      forma_pagamento: conta.forma_pagamento,
      cnpj: MaskHelper.cleanMaskCNPJ(conta.cnpj),
      inscricao_estadual: MaskHelper.cleanInscricaoEstadual(conta.inscricao_estadual),
      razao_social: conta.razao_social,
      responsavel_cpf: MaskHelper.cleanMaskCPF(conta.responsavel_cpf),
      responsavel_nome: conta.responsavel_nome,
      responsavel_data_nascimento: new Date(conta.responsavel_data_nascimento).toISOString(),
      nome_plano: conta.plano_selecionado_completo.nome,
      email: conta.email,
      email_financeiro: conta.email_financeiro,
      telefone: MaskHelper.cleanMaskTelefone(conta.telefone),
      cep: MaskHelper.cleanMaskCEP(conta.cep),
      rua: conta.rua,

      numero: conta.numero.toString(),

      bairro: conta.bairro,
      segmento: JSON.stringify(segmentos),
      id_estado: conta.id_estado,
      id_municipio: conta.id_municipio,

      dia_cobranca: 1,
      data_validade: DateTimeProvider.addTime(1, 'year').toISOString(),
      desconto_mensal: 0,

      condicao_pagamento: conta.plano_selecionado_completo.condicao_pagamento,
      limite_uso_credito_pos_pago: conta.plano_selecionado_completo.limite_uso_credito_pos_pago,
      pos_embarque_habilitado: conta.modulos_selecionados.gestao ? "Sim" : "Não",
      seguro_habilitado: "Não",
      embarque_valor_adicional_pos_limite: conta.plano_selecionado_completo.embarque_valor_adicional_pos_limite,
      cotacao_valor_modulo: conta.plano_selecionado_completo.cotacao_valor_modulo,
      cotacao_quantidade_mensal: conta.plano_selecionado_completo.cotacao_quantidade_mensal,
      cotacao_valor_adicional_pos_limite: conta.plano_selecionado_completo.cotacao_quantidade_mensal,
      usuario_valor_adicional_pos_limite: conta.plano_selecionado_completo.usuario_valor_adicional_pos_limite,
      frota_controle_veiculo: conta.plano_selecionado_completo.frota_controle_veiculo,
      frota_quantidade_veiculo: conta.plano_selecionado_completo.frota_quantidade_veiculo,
      suporte: conta.plano_selecionado_completo.suporte,
      criacao_qtd_usuarios: conta.plano_selecionado_completo.quantidade_usuarios,
      controle_frota: conta.plano_selecionado_completo.controle_frota,
      cotacao_habilitada: conta.modulos_selecionados.cotacao ? "Sim" : "Não",
      integracao: conta.plano_selecionado_completo.integracao,
      transportador_representante: conta.plano_selecionado_completo.transportador_representante,
      pos_embarque_adicional_por_embarque_cobranca: conta.plano_selecionado_completo.pos_embarque_adicional_por_embarque_cobranca,
      conta_gratuita: conta.plano_selecionado === PlanosEnum.TRIAL || conta.plano_selecionado === PlanosEnum.GRATIS ? "Sim" : "Não",
      cotacao_tarifacao: conta.plano_selecionado_completo.cotacao_tarifacao,
      cotacao_tarifacao_valor: conta.plano_selecionado_completo.cotacao_tarifacao_valor,
      regime_tributario: conta.regime_tributario,
      data_abertura_empresa: conta.data_abertura_empresa ? new Date(conta.data_abertura_empresa).toISOString() : new Date().toISOString(),
      realiza_transporte_container: conta.realiza_transporte_container,
      faturamento_anual: conta.faturamento_anual,
      funcionarios: conta.funcionarios,
      tipos_carga_transportada: JSON.stringify(tipoCargas),
      tomador_setor_economico: conta.setor_economico === "Outros" ? conta.outro_setor_economico : conta.setor_economico,
      transportadora_perfil_carrocerias: conta.transportadora_perfil_carrocerias?.length !== 0 ? JSON.stringify(conta.transportadora_perfil_carrocerias) : "",

      transportadora_seguradora: conta.transportadora_seguradora!,
      transportadora_cobertura_seguro_por_carga: Number(conta.transportadora_cobertura_seguro_por_carga),
      transportadora_vigencia_seguro: conta.transportadora_vigencia_seguro ? conta.transportadora_vigencia_seguro : DateTimeProvider.addTime(1, 'year').toISOString(),
      transportadora_possui_seguro_ambiental: conta.transportadora_possui_seguro_ambiental!,
      transportadora_possui_gerenciadora_de_risco: conta.transportadora_possui_gerenciadora_de_risco!,
      transportadora_gerenciador_de_risco: conta.transportadora_possui_gerenciadora_de_risco === 'Sim' ? conta.transportadora_gerenciador_de_risco! : 'Nenhum',
      transportadora_frota_propria_numero_caminhoes_cavalos: conta.transportadora_frota_propria_numero_caminhoes_cavalos!,
      transportadora_frota_propria_numero_motoristas: conta.transportadora_frota_propria_numero_motoristas!,
      transportadora_frota_agregada_numero_caminhoes_cavalos: conta.transportadora_frota_agregada_numero_caminhoes_cavalos!,
      transportadora_frota_agregada_numero_motoristas: conta.transportadora_frota_agregada_numero_motoristas!,

      informou_informacoes_adicionais: conta.cadastrou_informacoes_adicionais ? 'Sim' : 'Não',
      produtor_rural: conta.produtor_rural === '' ? conta.produtor_rural = 'Não' : conta.produtor_rural,
      cooperativa: conta.cooperativa === '' ? conta.cooperativa = 'Não' : conta.cooperativa,
      contribuinte_icms: conta.plano_selecionado_completo.cotacao_tarifacao,
      nome_fantasia: conta.nome_fantasia,
      valor_contratado: conta.modulos_selecionados.cotacao && conta.modulos_selecionados.gestao ? (conta.plano_selecionado_completo.cotacao_valor_modulo + conta.plano_selecionado_completo.pos_embarque_valor_modulo - ((conta.plano_selecionado_completo.cotacao_valor_modulo + conta.plano_selecionado_completo.pos_embarque_valor_modulo) * 20 / 100)) : conta.modulos_selecionados.gestao ? conta.plano_selecionado_completo.pos_embarque_valor_modulo : conta.modulos_selecionados.cotacao ? conta.plano_selecionado_completo.cotacao_valor_modulo : 0,
      tamanho_frota: conta.plano_selecionado_completo.tamanho_frota,
      valor_cobranca: conta.modulos_selecionados.cotacao && conta.modulos_selecionados.gestao ? (conta.plano_selecionado_completo.cotacao_valor_modulo + conta.plano_selecionado_completo.pos_embarque_valor_modulo - ((conta.plano_selecionado_completo.cotacao_valor_modulo + conta.plano_selecionado_completo.pos_embarque_valor_modulo) * 20 / 100)) : conta.modulos_selecionados.gestao ? conta.plano_selecionado_completo.pos_embarque_valor_modulo : conta.modulos_selecionados.cotacao ? conta.plano_selecionado_completo.cotacao_valor_modulo : 0,
    }
  }

  public static buildTrialAccount(conta: ContratacaoDTO, frete: FreteCompletoDTO, cotacao: CotacaoCompletaDTO): ContaTrialDTO {
    let segmentos = []
    let tipoCargas = []

    if (conta.segmentos.includes('Outros')) {
      segmentos = conta.segmentos
      segmentos.push(conta.outro_segmento)
    } else {
      segmentos = conta.segmentos
    }

    if (conta.tipo_cargas.includes('Outros')) {
      tipoCargas = conta.tipo_cargas
      tipoCargas.push(conta.outros_tipos_cargas)
    } else {
      tipoCargas = conta.tipo_cargas
    }

    return {
      solicitacao: {
        id_usuario: conta.id_usuario.toString(),
        tipo: conta.tipo,
        nome_empresa: conta.nome_fantasia,
        cnpj: MaskHelper.cleanMaskCNPJ(conta.cnpj),
        telefone: MaskHelper.cleanMaskTelefone(conta.telefone),
        email: conta.email,
        email_financeiro: conta.email_financeiro,
        segmento: JSON.stringify(conta.segmentos),
        rua: conta.rua,
        numero: conta.numero.toString(),
        cep: MaskHelper.cleanMaskCEP(conta.cep),
        id_estado: conta.id_estado,
        id_municipio: conta.id_municipio,
        bairro: conta.bairro,
        inscricao_estadual: MaskHelper.cleanInscricaoEstadual(conta.inscricao_estadual),
        produtor_rural: conta.produtor_rural,
        cooperativa: conta.cooperativa,
        regime_tributario: conta.regime_tributario,
        data_abertura_empresa: conta.data_abertura_empresa ? new Date(conta.data_abertura_empresa).toISOString() : new Date().toISOString(),
        realiza_transporte_container: conta.realiza_transporte_container,
        faturamento_anual: conta.faturamento_anual,
        funcionarios: conta.funcionarios,
        tipos_carga_transportada: JSON.stringify(tipoCargas),
        tomador_setor_economico: conta.setor_economico === "Outros" ? conta.outro_setor_economico : conta.setor_economico,
        transportadora_perfil_carrocerias: conta.transportadora_perfil_carrocerias?.length !== 0 ? JSON.stringify(conta.transportadora_perfil_carrocerias) : "",
        transportadora_seguradora: conta.transportadora_seguradora!,
        transportadora_cobertura_seguro_por_carga: Number(conta.transportadora_cobertura_seguro_por_carga),
        transportadora_vigencia_seguro: conta.transportadora_vigencia_seguro ? conta.transportadora_vigencia_seguro : DateTimeProvider.addTime(1, 'year').toISOString(),
        transportadora_possui_seguro_ambiental: conta.transportadora_possui_seguro_ambiental!,
        transportadora_possui_gerenciadora_de_risco: conta.transportadora_possui_gerenciadora_de_risco!,
        transportadora_gerenciador_de_risco: conta.transportadora_gerenciador_de_risco!,
        transportadora_frota_propria_numero_caminhoes_cavalos: conta.transportadora_frota_propria_numero_caminhoes_cavalos!,
        transportadora_frota_propria_numero_motoristas: conta.transportadora_frota_propria_numero_motoristas!,
        transportadora_frota_agregada_numero_caminhoes_cavalos: conta.transportadora_frota_agregada_numero_caminhoes_cavalos!,
        transportadora_frota_agregada_numero_motoristas: conta.transportadora_frota_agregada_numero_motoristas!,
        informou_informacoes_adicionais: conta.cadastrou_informacoes_adicionais ? 'Sim' : 'Não'
      },
      frete: conta.cadastro_frete_ativo ? JSON.stringify(ContaFactory.buildObjectFrete(conta, frete)) : "",
      cotacao: conta.cadastro_cotacao_ativo ? JSON.stringify(ContaFactory.buildObjectCotacao(cotacao)) : ""
    }
  }

  private static buildObjectFrete(conta: ContratacaoDTO, frete: FreteCompletoDTO) {
    const { Embarcador, Tomador, Frete } = frete
    Embarcador.cnpj = MaskHelper.cleanMaskCNPJ(conta.cnpj)
    Embarcador.email = conta.email
    Embarcador.nome = conta.nome_fantasia
    Embarcador.telefone_celular = MaskHelper.cleanMaskTelefone(conta.telefone)

    Tomador.cnpj = MaskHelper.cleanMaskCNPJ(conta.cnpj)
    Tomador.email = conta.email
    Tomador.nome = conta.nome_fantasia

    Frete.frete_motorista_valor = MaskHelper.cleanMoney(Frete.frete_motorista_valor_text)
    Frete.frete_transportadora_valor = MaskHelper.cleanMoney(Frete.frete_transportadora_valor_text)

    return frete
  }

  private static buildObjectCotacao(cotacao: CotacaoCompletaDTO) {
    cotacao.cotacao_frete.valor = MaskHelper.cleanMoney(cotacao.cotacao_frete.valor_text)
    cotacao.cotacao_frete.ad_valorem_valor = MaskHelper.cleanMoney(cotacao.cotacao_frete.ad_valorem_valor_text)
    cotacao.cotacao_frete.vale_pedagio_valor = MaskHelper.cleanMoney(cotacao.cotacao_frete.vale_pedagio_valor_text)

    cotacao.cotacao_frete.data_inicio_cotacao = DateTimeProvider.getDateWithHoraAndDate(cotacao.cotacao_frete._data_inicio_cotacao, cotacao.cotacao_frete._hora_inicio_cotacao)
    cotacao.cotacao_frete.data_termino_cotacao = DateTimeProvider.getDateWithHoraAndDate(cotacao.cotacao_frete._data_termino_cotacao, cotacao.cotacao_frete._hora_termino_cotacao)
    return cotacao
  }


}
