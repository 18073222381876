import moment from "moment"

export default class DateTimeProvider {

    public static now(format = "DD-MM-YYYY") {
        return moment().format(format)
    }

    public static getDateTime(date: string, format = "DD-MM-YYYY") {
        return moment(date).format(format)
    }

    public static addTime(quantidade: number, quantidadeTemporal: moment.unitOfTime.DurationConstructor, date = moment()) {
        return moment(date).add(quantidade, quantidadeTemporal).toDate()
    }

    public static getDateWithHoraAndDate(date: string, hour: string, format = "DD-MM-YYYYTHH:mm") {
        const data = moment(date)
        const hora = hour.split(":")
        data.set({
            hour: Number(hora[0]),
            minute: Number(hora[1]),
        })

        return data.format(format)
    }

} 