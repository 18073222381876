


























































































































import ErrorHandling from "@/domains/application/ErrorHandling"
import { OldFilterDTO } from "@/domains/application/OldFilterDTO"
import { SortDTO } from "@/domains/application/SortDTO"
import { NavegacaoContratacao, NavegacaoContratacaoTipoCadastro } from "@/domains/contratacao/ContratacaoEnum"
import CotacaoCompletaDTO, { CotacaoProdutoDTO } from "@/domains/cotacao/CotacaoCompletaDTO"
import EmbalagemAPI from "@/domains/embalagem/EmbalagemAPI"
import EmbalagemDTO from "@/domains/embalagem/EmbalagemDTO"
import EmbalagemHelper from "@/domains/embalagem/EmbalagemHelper"
import ProdutoGenericoAPI from "@/domains/produtoGenerico/ProdutoGenericoAPI"
import ProdutoGenericoDTO from "@/domains/produtoGenerico/ProdutoGenericoDTO"
import ProdutoGenericoHelper from "@/domains/produtoGenerico/ProdutoGenericoHelper"
import { Vue, Component, Watch } from "vue-property-decorator"
import { mapGetters } from "vuex"
import CotacaoBO from "@/domains/cotacao/CotacaoBO"
import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO"
import CotacaoHelper from "@/domains/cotacao/CotacaoHelper"

@Component({
  computed: {
    ...mapGetters({
      cotacaoCompleta: "cotacaostate/cotacaoCompleta",
      contratacao: 'contratacaostate/contratacao',
    }),
  },
})
export default class ProdutosCotacao extends Vue {
  contratacao!: ContratacaoDTO
  cotacaoCompleta!: CotacaoCompletaDTO
  navegacaoContratacao = NavegacaoContratacao
  tipoCadastro = NavegacaoContratacaoTipoCadastro

  listaDeProdutos?: ProdutoGenericoDTO[] = []
  listaDeEmbalagem?: EmbalagemDTO[] = []

  produtoSelecionado: ProdutoGenericoDTO = { id_produto_generico: 0, nome: "" }
  embalagemSelecionada: EmbalagemDTO = { id_embalagem: 0, descricao: "" }

  carregandoListaDeProdutos = false
  carregandoListaDeEmbalagem = false

  produtoInformado?= ""
  embalagemInformada?= ""
  descricaoProduto = ""
  pesoProduto = 0

  skipProdutos = 0
  takeProdutos = 100
  sortProdutos: SortDTO = {
    selector: "nome",
    desc: false,
  }
  filtrosProdutos: OldFilterDTO = { ...ProdutoGenericoHelper.getFilter() }

  skipEmbalagem = 0
  takeEmbalagem = 100
  sortEmbalagem: SortDTO = {
    selector: "descricao",
    desc: false,
  }
  filtrosEmbalagem: OldFilterDTO = { ...EmbalagemHelper.getFilter() }

  mounted() {
    this.buscarProdutos()
    this.buscarEmbalagens()
    this.sugerirPesoNovoProduto()
  }

  buscarProdutos() {
    this.carregandoListaDeProdutos = true
    new ProdutoGenericoAPI()
      .buscarProdutos(this.skipProdutos, this.takeProdutos, this.sortProdutos, this.filtrosProdutos)
      .then((r) => {
        this.listaDeProdutos = r.data
        this.carregandoListaDeProdutos = false
      })
      .catch((e) => {
        this.carregandoListaDeProdutos = false
        this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
        this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
      })
  }

  buscarEmbalagens() {
    this.carregandoListaDeEmbalagem = true
    new EmbalagemAPI()
      .buscarEmbalagens(this.skipProdutos, this.takeEmbalagem, this.sortEmbalagem, this.filtrosEmbalagem)
      .then((r) => {
        this.listaDeEmbalagem = r.data
        this.carregandoListaDeEmbalagem = false
      })
      .catch((e) => {
        this.carregandoListaDeEmbalagem = false
        this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
        this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
      })
  }

  verificarSePodeProsseguir() {
    try {
      CotacaoBO.validarInformacoesProdutos(this.cotacaoCompleta.produtos)
      if (CotacaoHelper.verificarInformacoesProdutos(this.cotacaoCompleta.produtos)) this.$store.dispatch("contratacaostate/adicionarNovaAba", 'FINALIZAR_COTACAO')
    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(error))
    }
  }

  sugerirPesoNovoProduto() {
    let valorInicial = 0
    let somaProdutosAtual = this.cotacaoCompleta.produtos.reduce((acumulador, valorAtual) => acumulador + valorAtual.peso, valorInicial)
    this.pesoProduto = this.cotacaoCompleta.cotacao_frete.peso - somaProdutosAtual
  }

  @Watch("embalagemInformada")
  onChangeEmbalagemInformada(embalagemInformada: string) {
    //TODO verificar se tem como fazer isso de uma forma melhor, nao consigo acessar o filtro de fora do escopo desta funcao, ele se perde por algum motivo que eu nao sei
    if (this.carregandoListaDeEmbalagem) return
    if (!embalagemInformada) {
      this.embalagemInformada = "%%"
      this.embalagemSelecionada = { id_embalagem: 0, descricao: "" }
      return
    }

    this.carregandoListaDeEmbalagem = true
    new EmbalagemAPI()
      .buscarEmbalagens(this.skipProdutos, this.takeEmbalagem, this.sortEmbalagem, { descricao: { field: "descricao", rule: "contains", value: `%${embalagemInformada}%` } })
      .then((r) => {
        this.listaDeEmbalagem = r.data
        this.carregandoListaDeEmbalagem = false
      })
      .catch((e) => {
        this.carregandoListaDeEmbalagem = false
        this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
        this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
      })
  }

  @Watch("produtoInformado")
  onChangeProdutoInformado(produtoInformado: string) {
    //TODO verificar se tem como fazer isso de uma forma melhor, nao consigo acessar o filtro de fora do escopo desta funcao, ele se perde por algum motivo que eu nao sei
    if (this.carregandoListaDeProdutos) return
    if (!produtoInformado) {
      this.produtoInformado = "%%"
      this.produtoSelecionado = { id_produto_generico: 0, nome: "" }
      return
    }

    this.carregandoListaDeProdutos = true
    new ProdutoGenericoAPI()
      .buscarProdutos(this.skipProdutos, this.takeProdutos, this.sortProdutos, { nome: { field: "nome", rule: "contains", value: `%${produtoInformado}%` } })
      .then((r) => {
        this.listaDeProdutos = r.data
        this.sugerirPesoNovoProduto()
        this.carregandoListaDeProdutos = false
      })
      .catch((e) => {
        this.carregandoListaDeProdutos = false
        this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
        this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
      })
  }

  adicionarNovosProdutos() {
    try {
      const produto: CotacaoProdutoDTO = {
        descricao_generica: this.produtoSelecionado.nome,
        quantidade: 1,
        peso: this.pesoProduto,
        embalagem: this.embalagemSelecionada.descricao,
        embalagem_unidade_medida: "KG",
        descricao_especifica: this.descricaoProduto,
      }

      CotacaoBO.validarInformacoesDoProduto(produto)

      this.$store.dispatch("cotacaostate/setProduto", produto)
      this.limparCampos()
      this.sugerirPesoNovoProduto()
    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(error))
    }
  }

  deletarProduto(index: number) {
    this.$store.dispatch("cotacaostate/deleteProduto", index)
  }

  limparCampos() {
    this.produtoInformado = ""
    this.embalagemInformada = ""
    this.pesoProduto = 0
    this.descricaoProduto = ""
    this.embalagemSelecionada = { id_embalagem: 0, descricao: "" }
    this.produtoSelecionado = { id_produto_generico: 0, nome: "" }
  }

}
