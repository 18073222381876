














































































































































































import ErrorHandling from "@/domains/application/ErrorHandling"
import { SortDTO } from "@/domains/application/SortDTO"
import CarroceriaAPI from "@/domains/carroceria/CarroceriaAPI"
import CarroceriaHelper from "@/domains/carroceria/CarroceriaHelper"
import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO"
import { NavegacaoContratacao, NavegacaoContratacaoTipoCadastro } from "@/domains/contratacao/ContratacaoEnum"
import CotacaoBO from "@/domains/cotacao/CotacaoBO"
import CotacaoCompletaDTO, { CotacaoCarroceriaDTO } from "@/domains/cotacao/CotacaoCompletaDTO"
import CotacaoHelper from "@/domains/cotacao/CotacaoHelper"
import { FreteCarroceriaDTO } from "@/domains/frete/FreteDTO"
import { Vue, Component, Watch } from "vue-property-decorator"
import { mapActions, mapGetters } from "vuex"

@Component({
  computed: {
    ...mapGetters({
      cotacaoCompleta: "cotacaostate/cotacaoCompleta",
      contratacao: 'contratacaostate/contratacao',
    }),
  },
  methods: {
    ...mapActions("cotacaostate", ["codigoreferencia", "peso", "valor", "advalorem_valor", "valepedagio_valor", "estimativaentrega_dias"]),
  },
})
export default class InformacoesCotacao extends Vue {
  contratacao!: ContratacaoDTO
  cotacaoCompleta!: CotacaoCompletaDTO
  navegacaoContratacao = NavegacaoContratacao
  tipoCadastro = NavegacaoContratacaoTipoCadastro
  carrocerias: FreteCarroceriaDTO[] = []
  carregandoCarrocerias = false
  skip = 0
  take = 100
  sort: SortDTO = {
    selector: "descricao",
    desc: false,
  }
  filters = { ...CarroceriaHelper.getFilter() }
  money = {
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    suffix: '',
    precision: 2,
  }

  percentage = {
    decimal: ',',
    suffix: ' %',
    precision: 2,
  }

  mounted() {
    this.buscarCarrocerias()
  }

  buscarCarrocerias() {
    this.carregandoCarrocerias = true
    new CarroceriaAPI()
      .buscarCarrocerias(this.skip, this.take, this.sort, this.filters)
      .then((r) => {
        const carrocerias = r.data
        carrocerias.forEach((element) => {
          this.carrocerias.push({ descricao_carroceria: element.descricao })
        })
        this.carregandoCarrocerias = false
      })
      .catch((e) => {
        this.carregandoCarrocerias = false
        this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
        this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
      })
  }

  @Watch("tipo_precificacao")
  onChangeTipoPrecificacao() {
    this.limparDadosTipoPrecificacao()
  }

  @Watch("tipo_cotacao")
  onChangeTipoCotacao() {
    this.tipo_precificacao = "ALL_IN"
  }

  limparDadosTipoPrecificacao() {
    this.estimativa_entrega_habilitado = "Não"
    this.vale_pedagio_habilitado = "Não"
    this.ad_valorem_habilitado = "Não"
    this.valor_habilitado = "Sim"
    this.$store.dispatch("cotacaostate/valor", 0)
    this.$store.dispatch("cotacaostate/advalorem_valor", 0)
    this.$store.dispatch("cotacaostate/valepedagio_valor", 0)
    this.$store.dispatch("cotacaostate/estimativaentrega_dias", 0)
  }

  verificarSePodeProsseguir() {
    try {
      CotacaoBO.validarInformacoesDaCotacao(this.cotacaoCompleta)
      if (CotacaoHelper.verificarInformacoesCotacao(this.cotacaoCompleta)) this.$store.dispatch("contratacaostate/adicionarNovaAba", 'LOCAL_COTACAO')
    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(error))
    }
  }

  get carrocerias_selecionadas() {
    return this.cotacaoCompleta.carrocerias
  }

  set carrocerias_selecionadas(carrocerias: CotacaoCarroceriaDTO[]) {
    this.$store.dispatch("cotacaostate/setCarrocerias", carrocerias)
  }

  get tipo_precificacao() {
    return this.cotacaoCompleta.cotacao_frete.tipo_precificacao
  }

  set tipo_precificacao(tipoPrecificacao: string) {
    this.$store.dispatch("cotacaostate/tipoprecificacao", tipoPrecificacao)
  }

  get tipo_cotacao() {
    return this.cotacaoCompleta.cotacao_frete.tipo_cotacao
  }

  set tipo_cotacao(tipoPrecificacao: string) {
    this.$store.dispatch("cotacaostate/tipocotacao", tipoPrecificacao)
  }

  get estimativa_entrega_habilitado() {
    return this.cotacaoCompleta.cotacao_frete.estimativa_entrega_habilitado
  }

  set estimativa_entrega_habilitado(estimativa_entrega_habilitado: string) {
    this.$store.dispatch("cotacaostate/estimativaentrega_habilitado", estimativa_entrega_habilitado)
  }

  get vale_pedagio_habilitado() {
    return this.cotacaoCompleta.cotacao_frete.vale_pedagio_habilitado
  }

  set vale_pedagio_habilitado(vale_pedagio_habilitado: string) {
    this.$store.dispatch("cotacaostate/valepedagio_habilitado", vale_pedagio_habilitado)
  }

  get ad_valorem_habilitado() {
    return this.cotacaoCompleta.cotacao_frete.ad_valorem_habilitado
  }

  set ad_valorem_habilitado(ad_valorem_habilitado: string) {
    this.$store.dispatch("cotacaostate/advalorem_habilitado", ad_valorem_habilitado)
  }

  get valor_habilitado() {
    return this.cotacaoCompleta.cotacao_frete.valor_habilitado
  }

  set valor_habilitado(valor_habilitado: string) {
    this.$store.dispatch("cotacaostate/valorhabilitado", valor_habilitado)
  }

}
