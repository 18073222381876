import HttpProvider from "@/providers/HttpProvider"
import { AxiosResponse } from "axios"
import { ContaCompletaDTO, ContaPagamento, ContaTrialDTO, ResponseCadastroConta } from "./ContaDTO"
import ResponsePagamentoContracaoDTO from "./ResponsePagamentoContracaoDTO"
import ValidarContaDTO from "./ValidarContaDTO"

class ContaAPI {

    private httpProvider: HttpProvider

    constructor() {
        this.httpProvider = new HttpProvider()
    }

    public firstPayOnHirePlan(pagamento: ContaPagamento): Promise<AxiosResponse<ResponsePagamentoContracaoDTO>> {
        return this.httpProvider.axiosInstanceBase.post(`/conta/v2/${pagamento.id_conta}/realizar-pagamento-contratacao`, pagamento)
    }

    public createAccount(account: ContaCompletaDTO): Promise<AxiosResponse<ResponseCadastroConta>> {
        return this.httpProvider.axiosInstanceBase.post(`/conta/v2`, account)
    }

    public createTrialAccount(account: ContaTrialDTO) {
        return this.httpProvider.axiosInstanceBase.post(`/solicitacao-trial/v3`, account)
    }

    public validarCNPJNaOJO(cnpj: string, tipoConta: string): Promise<AxiosResponse<ValidarContaDTO>> {
        return this.httpProvider.axiosInstanceBase.get(`/conta/v2/validar-cnpj/${cnpj}/${tipoConta}`)
    }

}


export default ContaAPI
