
























































































































































































































































































































































































import Modal from '@/components/modal/Modal.vue'
import ErrorHandling from '@/domains/application/ErrorHandling'
import { NavegacaoContratacao, NavegacaoContratacaoTipoCadastro } from '@/domains/contratacao/ContratacaoEnum'
import CotacaoCompletaDTO from '@/domains/cotacao/CotacaoCompletaDTO'
import EstadoAPI from '@/domains/estado/EstadoAPI'
import { EstadoDTO } from '@/domains/estado/EstadoDTO'
import MunicipioAPI from '@/domains/municipio/MunicipioAPI'
import { MunicipioDTO } from '@/domains/municipio/MunicipioDTO'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import BuscaTransportadoraDTO, { FiltroBuscaTransportadoraCarroceriasDTO, FiltroBuscaTransportadoraDTO } from "@/domains/transportadora/BuscaTransportadoraDTO"
import BuscarTransportadoraAPI from "@/domains/transportadora/BuscarTransportadoraAPI"
import CotacaoBO from '@/domains/cotacao/CotacaoBO'
import ContratacaoDTO from '@/domains/contratacao/ContratacaoDTO'
import CotacaoHelper from '@/domains/cotacao/CotacaoHelper'


@Component({
  computed: {
    ...mapGetters({
      cotacaoCompleta: "cotacaostate/cotacaoCompleta",
      contratacao: 'contratacaostate/contratacao',
    }),
  },
  methods: {
    ...mapActions("cotacaostate", [
      "observacaotransportadora",
      "datainicio_cotacao_tela",
      "datatermino_cotacao_tela",
      "horainicio_cotacao_tela",
      "horatermino_cotacao_tela",
      "datainicio_vigencia",
      "datatermino_vigencia",
      "valor",
      "advalorem_valor",
      "valepedagio_valor",
      "estimativaentrega_dias"
    ]),
  },
  components: {
    Modal
  }
})
export default class FinalizacaoCotacao extends Vue {
  contratacao!: ContratacaoDTO
  cotacaoCompleta!: CotacaoCompletaDTO
  navegacaoContratacao = NavegacaoContratacao
  tipoCadastro = NavegacaoContratacaoTipoCadastro
  transportadorasPesquisadas: BuscaTransportadoraDTO[] = []
  transportadorasSelecionadas: BuscaTransportadoraDTO[] = []
  mostrandoModalEscolherTransportadora = false
  estado_selecionado: number | null = null
  municipio_selecionado: number | null = null

  estados: EstadoDTO[] = []
  municipios: MunicipioDTO[] = []
  carregandoEstados = false
  carregandoMunicipios = false
  carregandoTransportadoras = false

  money = {
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    suffix: '',
    precision: 2,
  }

  percentage = {
    decimal: ',',
    suffix: ' %',
    precision: 2,
  }

  mounted() {
    this.buscarEstados()
  }

  buscarEstados() {
    this.carregandoEstados = true
    new EstadoAPI()
      .buscarEstados()
      .then((r) => {
        this.estados = r.data
        this.carregandoEstados = false
      })
      .catch((e) => {
        this.carregandoEstados = false
        this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
        this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
      })
  }

  buscarTransportadoras() {
    this.carregandoTransportadoras = true

    let carroceriasSelecionadas: FiltroBuscaTransportadoraCarroceriasDTO[] = []
    this.cotacaoCompleta.carrocerias.forEach(el => carroceriasSelecionadas.push({ carroceria: el.descricao_carroceria }))

    const filtro: FiltroBuscaTransportadoraDTO = {
      id_estado: this.estado_selecionado ? this.estado_selecionado.toString() : null,
      id_municipio: this.municipio_selecionado ? this.municipio_selecionado.toString() : null,
      carrocerias: carroceriasSelecionadas
    }

    new BuscarTransportadoraAPI()
      .buscarTransportadoras(filtro)
      .then((r) => {
        this.transportadorasPesquisadas = r.data

        this.transportadorasSelecionadas.map(el => {
          const index = this.transportadorasPesquisadas.findIndex(element => el.id_transportadora === element.id_transportadora)
          this.transportadorasPesquisadas.splice(index, 1)
        })

        this.transportadorasPesquisadas.map(el => el.transportadora_selecionada = false)
        this.municipio_selecionado = null
        this.carregandoTransportadoras = false
      })
      .catch((e) => {
        this.carregandoTransportadoras = false
        this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
        this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
      })
  }

  buscarMunicipios(id_estado: number) {
    this.carregandoMunicipios = true
    new MunicipioAPI()
      .buscarMunicipios(id_estado)
      .then((r) => {
        this.municipios = r.data
        this.carregandoMunicipios = false
      })
      .catch((e) => {
        this.carregandoMunicipios = false
        this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
        this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
      })
  }

  verificarSePodeProsseguir() {
    try {
      CotacaoBO.validarFinalizacaoDaCotacao(this.cotacaoCompleta, this.transportadorasSelecionadas)
      if (CotacaoHelper.verificarFinalizacaoDaCotacao(this.cotacaoCompleta)) this.$store.dispatch("contratacaostate/adicionarNovaAba", 'FINALIZAR_COTACAO')

      setTimeout(() => {
        if (this.contratacao.abasCadastro.includes('DADOS_RESPONSAVEL') && this.contratacao.usuario_logado)
          this.$store.dispatch("contratacaostate/adicionarNovaAba", 'DESEJA_CADASTRAR_INFORMACOES_ADICIONAIS')
      }, 200)

    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(error))
    }
  }

  adicionarTransportadorasSelecionadas() {
    const transportadorasSelecionadas = this.transportadorasPesquisadas.filter(el => el.transportadora_selecionada)
    this.transportadorasSelecionadas = [...this.transportadorasSelecionadas, ...transportadorasSelecionadas]
    this.municipio_selecionado = null
    this.estado_selecionado = null
    this.transportadorasPesquisadas = []
    this.mostrandoModalEscolherTransportadora = false
  }

  get tipo_preco_frete() {
    return this.cotacaoCompleta.cotacao_frete.tipo_preco_frete
  }

  set tipo_preco_frete(tipo_preco_frete: string) {
    this.$store.dispatch("cotacaostate/tipopreco_frete", tipo_preco_frete)
  }

  get tipo_precificacao() {
    return this.cotacaoCompleta.cotacao_frete.tipo_precificacao
  }

  set tipo_precificacao(tipoPrecificacao: string) {
    this.$store.dispatch("cotacaostate/tipoprecificacao", tipoPrecificacao)
  }


  get tipo_cotacao() {
    return this.cotacaoCompleta.cotacao_frete.tipo_cotacao
  }

  set tipo_cotacao(tipoPrecificacao: string) {
    this.$store.dispatch("cotacaostate/tipocotacao", tipoPrecificacao)
  }

  get estimativa_entrega_habilitado() {
    return this.cotacaoCompleta.cotacao_frete.estimativa_entrega_habilitado
  }

  set estimativa_entrega_habilitado(estimativa_entrega_habilitado: string) {
    this.$store.dispatch("cotacaostate/estimativaentrega_habilitado", estimativa_entrega_habilitado)
  }

  get vale_pedagio_habilitado() {
    return this.cotacaoCompleta.cotacao_frete.vale_pedagio_habilitado
  }

  set vale_pedagio_habilitado(vale_pedagio_habilitado: string) {
    this.$store.dispatch("cotacaostate/valepedagio_habilitado", vale_pedagio_habilitado)
  }

  get ad_valorem_habilitado() {
    return this.cotacaoCompleta.cotacao_frete.ad_valorem_habilitado
  }

  set ad_valorem_habilitado(ad_valorem_habilitado: string) {
    this.$store.dispatch("cotacaostate/advalorem_habilitado", ad_valorem_habilitado)
  }

  get valor_habilitado() {
    return this.cotacaoCompleta.cotacao_frete.valor_habilitado
  }

  set valor_habilitado(valor_habilitado: string) {
    this.$store.dispatch("cotacaostate/valorhabilitado", valor_habilitado)
  }

  removerTransportadora(index: number) {
    this.transportadorasSelecionadas.splice(index, 1)
  }

  @Watch("tipo_precificacao")
  onChangeTipoPrecificacao() {
    this.limparDadosTipoPrecificacao()
  }

  limparDadosTipoPrecificacao() {
    this.estimativa_entrega_habilitado = "Não"
    this.vale_pedagio_habilitado = "Não"
    this.ad_valorem_habilitado = "Não"
    this.valor_habilitado = "Sim"
    this.$store.dispatch("cotacaostate/valor", 0)
    this.$store.dispatch("cotacaostate/advalorem_valor", 0)
    this.$store.dispatch("cotacaostate/valepedagio_valor", 0)
    this.$store.dispatch("cotacaostate/estimativaentrega_dias", 0)
  }

  @Watch("transportadorasSelecionadas")
  onChangeListaTransportadoras(newValue: BuscaTransportadoraDTO[]) {
    if (!newValue) return
    this.$store.dispatch("cotacaostate/setConvite", newValue)
  }

}

