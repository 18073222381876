


























































































































import { NavegacaoContratacao, NavegacaoContratacaoTipoCadastro } from "@/domains/contratacao/ContratacaoEnum"
import { FreteCompletoDTO, FreteProdutoDTO } from "@/domains/frete/FreteDTO"
import { Vue, Component, Watch } from "vue-property-decorator"
import { mapActions, mapGetters } from "vuex"
import ProdutoGenericoAPI from "@/domains/produtoGenerico/ProdutoGenericoAPI"
import ProdutoGenericoDTO from "@/domains/produtoGenerico/ProdutoGenericoDTO"
import EmbalagemDTO from "@/domains/embalagem/EmbalagemDTO"
import EmbalagemAPI from "@/domains/embalagem/EmbalagemAPI"
import ErrorHandling from "@/domains/application/ErrorHandling"
import { SortDTO } from "@/domains/application/SortDTO"
import ProdutoGenericoHelper from "@/domains/produtoGenerico/ProdutoGenericoHelper"
import { OldFilterDTO } from "@/domains/application/OldFilterDTO"
import EmbalagemHelper from "@/domains/embalagem/EmbalagemHelper"
import FreteBO from "@/domains/frete/FreteBO"
import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO"
import FreteHelper from "@/domains/frete/FreteHelper"

@Component({
  computed: {
    ...mapGetters({
      freteCompleto: "fretestate/freteCompleto",
      contratacao: 'contratacaostate/contratacao',
    }),
  },
  methods: {
    ...mapActions("fretestate", []),
  },
})
export default class ProdutosFrete extends Vue {
  contratacao!: ContratacaoDTO

  navegacaoContratacao = NavegacaoContratacao
  tipoCadastro = NavegacaoContratacaoTipoCadastro
  freteCompleto!: FreteCompletoDTO

  listaDeProdutos?: ProdutoGenericoDTO[] = []
  listaDeEmbalagem?: EmbalagemDTO[] = []

  produtoSelecionado: ProdutoGenericoDTO = { id_produto_generico: 0, nome: "" }
  embalagemSelecionada: EmbalagemDTO = { id_embalagem: 0, descricao: "" }

  carregandoListaDeProdutos = false
  carregandoListaDeEmbalagem = false

  produtoInformado?= ""
  embalagemInformada?= ""
  descricaoProduto = ""
  pesoProduto = 0

  skipProdutos = 0
  takeProdutos = 100
  sortProdutos: SortDTO = {
    selector: "nome",
    desc: false
  }
  filtrosProdutos: OldFilterDTO = { ...ProdutoGenericoHelper.getFilter() }

  skipEmbalagem = 0
  takeEmbalagem = 100
  sortEmbalagem: SortDTO = {
    selector: "descricao",
    desc: false
  }
  filtrosEmbalagem: OldFilterDTO = { ...EmbalagemHelper.getFilter() }

  mounted() {
    this.buscarProdutos()
    this.buscarEmbalagens()
    this.sugerirPesoNovoProduto()
  }

  buscarProdutos() {
    this.carregandoListaDeProdutos = true
    new ProdutoGenericoAPI().buscarProdutos(this.skipProdutos, this.takeProdutos, this.sortProdutos, this.filtrosProdutos).then(r => {
      this.listaDeProdutos = r.data
      this.carregandoListaDeProdutos = false
    }).catch(e => {
      this.carregandoListaDeProdutos = false
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
    })
  }

  buscarEmbalagens() {
    this.carregandoListaDeEmbalagem = true
    new EmbalagemAPI().buscarEmbalagens(this.skipProdutos, this.takeEmbalagem, this.sortEmbalagem, this.filtrosEmbalagem).then(r => {
      this.listaDeEmbalagem = r.data
      this.carregandoListaDeEmbalagem = false
    }).catch(e => {
      this.carregandoListaDeEmbalagem = false
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
    })
  }

  sugerirPesoNovoProduto() {
    let valorInicial = 0
    let somaProdutosAtual = this.freteCompleto.Produtos.reduce((acumulador, valorAtual) => acumulador + valorAtual.peso, valorInicial)
    this.pesoProduto = this.freteCompleto.Frete.peso - somaProdutosAtual
  }


  @Watch("embalagemInformada")
  onChangeEmbalagemInformada(embalagemInformada: string) {

    //TODO verificar se tem como fazer isso de uma forma melhor, nao consigo acessar o filtro de fora do escopo desta funcao, ele se perde por algum motivo que eu nao sei
    if (this.carregandoListaDeEmbalagem) return
    if (!embalagemInformada) {
      this.embalagemInformada = "%%"
      this.embalagemSelecionada = { id_embalagem: 0, descricao: "" }
      return
    }

    this.carregandoListaDeEmbalagem = true
    new EmbalagemAPI().buscarEmbalagens(this.skipProdutos, this.takeEmbalagem, this.sortEmbalagem, { descricao: { field: "descricao", rule: "contains", value: `%${embalagemInformada}%` } }).then(r => {
      this.listaDeEmbalagem = r.data
      this.carregandoListaDeEmbalagem = false
    }).catch(e => {
      this.carregandoListaDeEmbalagem = false
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
    })
  }

  @Watch("produtoInformado")
  onChangeProdutoInformado(produtoInformado: string) {

    //TODO verificar se tem como fazer isso de uma forma melhor, nao consigo acessar o filtro de fora do escopo desta funcao, ele se perde por algum motivo que eu nao sei
    if (this.carregandoListaDeProdutos) return

    if (!produtoInformado) {
      this.produtoInformado = "%%"
      this.produtoSelecionado = { id_produto_generico: 0, nome: "" }
      return
    }

    this.carregandoListaDeProdutos = true
    new ProdutoGenericoAPI().buscarProdutos(this.skipProdutos, this.takeProdutos, this.sortProdutos, { nome: { field: "nome", rule: "contains", value: `%${produtoInformado}%` } }).then(r => {
      this.listaDeProdutos = r.data
      this.sugerirPesoNovoProduto()
      this.carregandoListaDeProdutos = false
    }).catch(e => {
      this.carregandoListaDeProdutos = false
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(e))
    })
  }

  adicionarNovosProdutos() {
    try {
      const produto: FreteProdutoDTO = {
        descricao_generica: this.produtoSelecionado.nome,
        quantidade: 1,
        peso: this.pesoProduto,
        embalagem: this.embalagemSelecionada.descricao,
        embalagem_unidade_medida: "KG",
        descricao_especifica: this.descricaoProduto
      }

      FreteBO.validarInformacoesDoProduto(produto)

      this.$store.dispatch("fretestate/setProduto", produto)
      this.limparCampos()
      this.sugerirPesoNovoProduto()
    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(error))
    }
  }

  deletarProduto(index: number) {
    this.$store.dispatch("fretestate/deleteProduto", index)
  }

  limparCampos() {
    this.produtoInformado = ""
    this.embalagemInformada = ""
    this.pesoProduto = 0
    this.descricaoProduto = ""
    this.embalagemSelecionada = { id_embalagem: 0, descricao: "" }
    this.produtoSelecionado = { id_produto_generico: 0, nome: "" }
  }

  verificarSePodeProsseguir() {
    try {
      FreteBO.validarListaProduto(this.freteCompleto.Produtos)
      if (FreteHelper.verificarProdutosFrete(this.freteCompleto.Produtos)) this.$store.dispatch("contratacaostate/adicionarNovaAba", 'FINALIZACAO_FRETE')
    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(error))
    }
  }

}
