import HttpProvider from "@/providers/HttpProvider"
import { AxiosResponse } from "axios"
import { EntidadePessoaFisicaDTO } from "./EntidadePessoaFisicaDTO"
import { EntidadePessoaJuridicaDTO, EntidadePessoaJuridicaSintegraDTO } from "./EntidadePessoaJuridicaDTO"

class EntidadesAPI {

    private httpProvider: HttpProvider

    constructor() {
        this.httpProvider = new HttpProvider()
    }

    public buscarPessoaJuridica(cnpj: string): Promise<AxiosResponse<EntidadePessoaJuridicaDTO>> {
        return this.httpProvider.axiosInstanceEntidade.get(`/public/pessoa-juridica/${cnpj}`)
    }

    public buscarPessoaJuridicaSintegra(cnpj: string): Promise<AxiosResponse<EntidadePessoaJuridicaSintegraDTO>> {
        return this.httpProvider.axiosInstanceEntidade.get(`/public/pessoa-juridica/sintegra/${cnpj}`)
    }

    public buscarPessoaFisica(cpf: string): Promise<AxiosResponse<EntidadePessoaFisicaDTO>> {
        return this.httpProvider.axiosInstanceEntidade.get(`/public/pessoa-fisica/${cpf}`)
    }

}


export default EntidadesAPI
