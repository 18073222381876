import BusinessError from "../application/BusinessError"
import { MaskHelper } from "../application/MaskHelper"
import { PlanosRamosEnum } from "../planos/PlanosEnum"
import ContratacaoDTO from "./ContratacaoDTO"

class ContratacaoBO {

    public static validateDadosResponsavel(contratacao: ContratacaoDTO) {
        if (['', 0, null, undefined].includes(contratacao.responsavel_nome)) throw new BusinessError('O campo nome está com valor inválido')
        if (['', 0, null, undefined].includes(contratacao.responsavel_cpf)) throw new BusinessError('O campo CPF está com valor inválido')
        if (!MaskHelper.validarCPF(contratacao.responsavel_cpf)) throw new BusinessError('Informe um CPF válido')
        if (['', 0, null, undefined].includes(contratacao.email)) throw new BusinessError('O campo email está com valor inválido')
        if (['', 0, null, undefined].includes(contratacao.email_financeiro)) throw new BusinessError('O campo email do financeiro está com valor inválido')
        if (['', 0, null, undefined].includes(contratacao.telefone)) throw new BusinessError('O campo telefone está com valor inválido')
        if (['', 0, null, undefined].includes(contratacao.responsavel_data_nascimento)) throw new BusinessError('A data de nascimento está inválida')
    }

    public static validateDadosDaEmpresa(contratacao: ContratacaoDTO) {
        if (['', 0, null, undefined].includes(contratacao.cnpj)) throw new BusinessError('O campo CNPJ está com valor inválido')
        if (!MaskHelper.validarCNPJ(contratacao.cnpj)) throw new BusinessError('Informe um CNPJ válido')
        if (['', 0, null, undefined].includes(contratacao.razao_social)) throw new BusinessError('O campo Razão social está com valor inválido')
        if (['', 0, null, undefined].includes(contratacao.nome_fantasia)) throw new BusinessError('O campo Nome fantasia está com valor inválido')
        if (['', 0, null, undefined].includes(contratacao.inscricao_estadual)) throw new BusinessError('O campo Inscrição estadual está com valor inválido')
        if (['', 0, null, undefined].includes(contratacao.rua)) throw new BusinessError('O campo rua está com valor inválido')
        if (['', 0, null, undefined].includes(contratacao.numero) || contratacao.numero > 99999999 || contratacao.numero < 0) throw new BusinessError('O campo número está com valor inválido')
        if (['', 0, null, undefined].includes(contratacao.cep)) throw new BusinessError('O campo cep está com valor inválido')
        if (['', 0, null, undefined].includes(contratacao.bairro)) throw new BusinessError('O campo bairro está com valor inválido')
        if (['', 0, null, undefined].includes(contratacao.id_estado)) throw new BusinessError('O campo estado está com valor inválido')
        if (['', 0, null, undefined].includes(contratacao.id_municipio)) throw new BusinessError('O campo município está com valor inválido')
    }

    public static validateInformacoesAdicionais(contratacao: ContratacaoDTO) {

        if (['', 0, null, undefined].includes(contratacao.regime_tributario)) throw new BusinessError('O campo regime tributário está com valor inválido')
        if (['', 0, null, undefined].includes(contratacao.faturamento_anual)) throw new BusinessError('O campo faturamento anual está com valor inválido')
        if (['', 0, null, undefined].includes(contratacao.data_abertura_empresa)) throw new BusinessError('O campo data de abertura da empresa está com valor inválido')
        if (['', 0, null, undefined].includes(contratacao.funcionarios)) throw new BusinessError('O campo funcionários está com valor inválido')
        if (contratacao.tipo_cargas.length === 0) throw new BusinessError('O campo tipo cargas está com valor inválido')
        if (contratacao.tipo_cargas.includes('Outros') && ['', 0, null, undefined].includes(contratacao.outros_tipos_cargas)) throw new BusinessError('O campo Qual tipo de carga? está com valor inválido')
        if (contratacao.segmentos.length === 0) throw new BusinessError('O campo Segmentos está com valor inválido')
        if (contratacao.segmentos.includes('Outros') && ['', 0, null, undefined].includes(contratacao.outro_segmento)) throw new BusinessError('O campo Qual segmento? está com valor inválido')
        if (!contratacao.termo_uso_aceito) throw new BusinessError('É necessário aceitar o termo de uso para prosseguir')
        if (['', 0, null, undefined].includes(contratacao.realiza_transporte_container)) throw new BusinessError('O campo Realiza transporte por container está com valor inválido')

        if (contratacao.tipo === PlanosRamosEnum.TOMADOR) {
            if (['', 0, null, undefined].includes(contratacao.produtor_rural)) throw new BusinessError('O campo produtor rural está com valor inválido')
            if (['', 0, null, undefined].includes(contratacao.setor_economico)) throw new BusinessError('O campo setor econômico está com valor inválido')
            if (['', 0, null, undefined].includes(contratacao.cooperativa)) throw new BusinessError('O campo cooperativa está com valor inválido')
            if (contratacao.setor_economico === 'Outros' && ['', 0, null, undefined].includes(contratacao.outro_setor_economico)) throw new BusinessError('O campo Qual setor economico? está com valor inválido')
        }

        if (contratacao.tipo === PlanosRamosEnum.TRANSPORTADORA) {
            if (['', 0, null, undefined].includes(contratacao.transportadora_seguradora)) throw new BusinessError('O campo seguradora está com valor inválido')
            if (['', 0, null, undefined].includes(contratacao.transportadora_cobertura_seguro_por_carga)) throw new BusinessError('O campo cobertura por carga está com valor inválido')
            if (['', 0, null, undefined].includes(contratacao.transportadora_vigencia_seguro)) throw new BusinessError('O campo vigência está com valor inválido')
            if (['', 0, null, undefined].includes(contratacao.transportadora_possui_seguro_ambiental)) throw new BusinessError('O campo possui seguro ambiental está com valor inválido')
            if (['', 0, null, undefined].includes(contratacao.transportadora_possui_gerenciadora_de_risco)) throw new BusinessError('O campo possui gerenciador de risco está com valor inválido')
            if (contratacao.transportadora_perfil_carrocerias?.length === 0) throw new BusinessError('O campo perfil carroceria está com valor inválido')
        }
    }

}

export default ContratacaoBO