import HttpProvider from "@/providers/HttpProvider"
import { AxiosResponse } from "axios"
import { AutenticacaoDTO } from "./AutenticacaoDTO"

class AutenticacaoAPI {

    private httpProvider: HttpProvider

    constructor() {
        this.httpProvider = new HttpProvider()
    }

    public autenticarUsuario(user: string, pass: string): Promise<AxiosResponse<AutenticacaoDTO>> {
        return this.httpProvider.axiosInstanceBase.post(`/auth/v2`, {}, {
            headers: {
                'jwtusername': user,
                'jwtpassword': pass
            }
        })
    }
}


export default AutenticacaoAPI
