import BusinessError from "../application/BusinessError"
import ContratacaoBO from "../contratacao/ContratacaoBO"
import ContratacaoDTO from "../contratacao/ContratacaoDTO"
import CotacaoBO from "../cotacao/CotacaoBO"
import CotacaoCompletaDTO from "../cotacao/CotacaoCompletaDTO"
import FreteBO from "../frete/FreteBO"
import { FreteCompletoDTO } from "../frete/FreteDTO"
import { IuguCreditCard } from "../iugu/iugu"

export default class ContaBO {

    public static validateAccount(conta: ContratacaoDTO) {
        ContratacaoBO.validateDadosDaEmpresa(conta)
        ContratacaoBO.validateDadosResponsavel(conta)

        if (conta.cadastrou_informacoes_adicionais)
            ContratacaoBO.validateInformacoesAdicionais(conta)

        if (!conta.termo_uso_aceito) throw new BusinessError('Para prosseguir é necessário aceitar os termos de uso e a política de privacidade')
    }

    public static validateCard(card: IuguCreditCard) {
        if (['', 0, null, undefined, NaN].includes(card.cardNumber)) throw new BusinessError('Informe o número do cartão')
        if (['', 0, null, undefined, NaN].includes(card.name)) throw new BusinessError('Informe o nome impresso no cartão')
        if (['', 0, null, undefined, NaN].includes(card.dueMonth)) throw new BusinessError('Informe o mês de vencimento')
        if (['', 0, null, undefined, NaN].includes(card.dueYear)) throw new BusinessError('Informe o ano do vencimento')
        if (['', 0, null, undefined, NaN].includes(card.verificationValue)) throw new BusinessError('Informe o código de segurança')
    }

    public static validateAccountTrial(conta: ContratacaoDTO, frete: FreteCompletoDTO, cotacao: CotacaoCompletaDTO) {
        ContratacaoBO.validateDadosDaEmpresa(conta)
        ContratacaoBO.validateDadosResponsavel(conta)


        if (conta.cadastrou_informacoes_adicionais)
            ContratacaoBO.validateInformacoesAdicionais(conta)

        if (conta.cadastro_frete_ativo) {
            FreteBO.validarInformacoesDoFrete(frete)
            FreteBO.validarLocalColetaEntrega(frete)
            FreteBO.validarListaProduto(frete.Produtos)
        }

        if (conta.cadastro_cotacao_ativo) {
            CotacaoBO.validarInformacoesDaCotacao(cotacao)
            CotacaoBO.validarInformacoesLocalDeColetaEntrega(cotacao)
            CotacaoBO.validarInformacoesProdutos(cotacao.produtos)
        }

        if (!conta.termo_uso_aceito) throw new BusinessError('Para prosseguir é necessário aceitar os termos de uso e a política de privacidade')
    }
}