import axios from "axios"
import BusinessError from "./BusinessError"

class ErrorHandling {

    public static tratarMensagemDeError(error: unknown): string {
        if (error instanceof BusinessError) {
            console.error(error.message)
            return error.message
        } else if (axios.isAxiosError(error)) {
            console.error(error.response?.data)
            return error.response?.data.message
        } else if ((error as string).includes('[BusinessError]')) { // Erro tratado para exceptions que vem especificadamente do js 
            const errorClean = (error as string).replace('[BusinessError]', '')
            return errorClean
        } else {
            console.error(error)
            return 'Erro interno, tente novamente mais tarde.'
        }
    }

}

export default ErrorHandling