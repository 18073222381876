import md5 from "md5"
import CadastroUsuarioDTO from "./CadastroUsuarioDTO"

export default class CadastroUsuarioFactory {

    public static buildCadastroUsuario(usuario: CadastroUsuarioDTO): CadastroUsuarioDTO {
        return {
            apelido: usuario.sobrenome.trim(),
            celular: usuario.celular.replace(/[^0-9,]*/g, ''),
            cpf: usuario.cpf.replace(/[^0-9,]*/g, ''),
            email: usuario.email.trim(),
            nome: usuario.nome.trim(),
            sobrenome: usuario.sobrenome.trim(),
            ativo: "Sim",
            id_termo_uso_plataforma: 3,
            senha: md5(usuario.senha.trim()),
        }
    }
}