















































































































































import BusinessError from "@/domains/application/BusinessError"
import ConfigDTO from "@/domains/application/ConfigDTO"
import ErrorHandling from "@/domains/application/ErrorHandling"
import JWTDTO from "@/domains/application/JWTDTO"
import { MaskHelper } from "@/domains/application/MaskHelper"
import AutenticacaoAPI from "@/domains/autenticacao/AutenticacaoAPI"
import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO"
import { NavegacaoContratacao } from "@/domains/contratacao/ContratacaoEnum"
import EntidadesAPI from "@/domains/entidades/EntidadesAPI"
import UsuarioAPI from "@/domains/usuario/UsuarioAPI"
import LocalStorageProvider, { LocalStorageEnum } from "@/providers/LocalStorageProviders"
import md5 from "md5"
import { Vue, Component } from "vue-property-decorator"
import { mapActions, mapGetters } from "vuex"
import jwt_decode from "jwt-decode"

@Component({
  computed: {
    ...mapGetters({
      contratacao: "contratacaostate/contratacao",
    }),
  },
  methods: {
    ...mapActions("contratacaostate", ["setResponsavelNome", "setResponsavelCpf", "setEmail", "setTelefone", "setplano_selecionado", "settipo", "setEmailFinanceiro", "setResponsavelDataNascimento"]),
  },
})
export default class DadosDoResponsavel extends Vue {
  contratacao!: ContratacaoDTO
  navegacaoContratacao = NavegacaoContratacao
  realizandoLogin = false
  senhaLogin = ""
  mostrarLogin = false
  mostrarSenha = false
  carregandoInformacoesDoUsuario = false
  mostrarBotaoAvancar = true


  async buscarUsuarioNaReceita() {
    try {

      if (this.carregandoInformacoesDoUsuario) return
      this.carregandoInformacoesDoUsuario = true


      this.$store.dispatch("appstate/setSnackbarWarningVisibility", true)
      this.$store.dispatch("appstate/setSnackbarWarningMessage", "Estamos validando seu CPF, isto pode demorar um pouco...")

      this.$store.dispatch("contratacaostate/setfinalizado_cadastro_usuario", false)
      let cpf = this.contratacao.responsavel_cpf

      if (!MaskHelper.validarCPF(cpf)) {
        this.mostrarLogin = false
        throw new BusinessError("Informe um cpf válido")
      }

      cpf = MaskHelper.cleanMaskCPF(cpf)
      const response = await new UsuarioAPI().validarSituacaoCpf(cpf)
      this.$store.dispatch("contratacaostate/setsituacao_cpf_usuario", response.data.acao)


      if (response.data.acao === 'cadastrar') {

        const responseReceita = await new EntidadesAPI().buscarPessoaFisica(cpf)
        if (responseReceita.data.constaObito || responseReceita.data.situacaoCadastral !== 'REGULAR') throw new BusinessError("O CPF informado está inválido")

        this.$store.dispatch("contratacaostate/setfinalizado_cadastro_usuario", true)
        this.$store.dispatch("contratacaostate/setNomeSobrenomeCadastroNovaConta", responseReceita.data.nome)
        this.mostrarLogin = false

      } else if (response.data.acao === 'entrar' && this.contratacao.usuario_logado) {
        this.$store.dispatch("contratacaostate/setfinalizado_cadastro_usuario", true)

      } else if (response.data.acao === 'ativar' && !this.contratacao.usuario_logado) {
        this.$store.dispatch("contratacaostate/setfinalizado_cadastro_usuario", true)

      } else {
        this.mostrarLogin = true

      }

      this.carregandoInformacoesDoUsuario = false
      this.mostrarBotaoAvancar = false
      this.$store.dispatch("appstate/setSnackbarWarningVisibility", false)
      this.watchCPF()

    } catch (error) {
      this.mostrarBotaoAvancar = true
      this.carregandoInformacoesDoUsuario = false
      this.$store.dispatch("appstate/setSnackbarWarningVisibility", false)
      this.$store.dispatch("contratacaostate/setfinalizado_cadastro_usuario", false)
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(error))
    }
  }

  async realizarLogin() {
    try {
      if (this.realizandoLogin) return
      this.realizandoLogin = true
      const cpf = MaskHelper.cleanMaskCPF(this.contratacao.responsavel_cpf)
      const response = await new AutenticacaoAPI().autenticarUsuario(cpf, md5(this.senhaLogin))
      const token = response.data.token
      if (!token) return

      const decodedToken = jwt_decode(token)
      const decodedTokenObject = decodedToken as JWTDTO

      LocalStorageProvider.store<ConfigDTO>({ jwt: token, id_usuario: decodedTokenObject.id_usuario, cpf: cpf }, LocalStorageEnum.CONFIG_KEY)

      this.$store.dispatch("contratacaostate/setid_usuario", Number(decodedTokenObject.id_usuario))
      this.$store.dispatch("contratacaostate/setusuario_logado", true)

      this.realizandoLogin = false
      this.$store.dispatch("contratacaostate/setfinalizado_cadastro_usuario", true)
      this.mostrarLogin = false
      this.senhaLogin = ""
      this.buscarInformacoesDoUsuario()
    } catch (error) {
      this.realizandoLogin = false
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(error))
    }
  }

  async buscarInformacoesDoUsuario() {
    try {
      const response = await new UsuarioAPI().buscarInformacoesPublicasUsuario(MaskHelper.cleanMaskCPF(this.contratacao.responsavel_cpf))
      this.$store.dispatch("contratacaostate/setInformacoesPublicasUsuario", response.data)
    } catch (error) {
      this.realizandoLogin = false
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true)
      this.$store.dispatch("appstate/setSnackbarErrorMessage", ErrorHandling.tratarMensagemDeError(error))
    }
  }

  timer: number | null | undefined = null

  watchCPF() {
    this.$watch('contratacao.responsavel_cpf', () => {

      if (this.timer)
        clearTimeout(this.timer)

      if (this.contratacao.responsavel_cpf !== '' && this.contratacao.responsavel_cpf.length === 14) {

        this.timer = setTimeout(() => {
          this.senhaLogin = ""
          this.mostrarSenha = false
          this.buscarUsuarioNaReceita()
        }, 1000)

      }
    })
  }

  alterarConta() {
    LocalStorageProvider.remove(LocalStorageEnum.CONFIG_KEY)
    this.$store.dispatch("contratacaostate/setusuario_logado", false)
    this.$store.dispatch("contratacaostate/cleanInformacoesResponsavel")
  }

}
