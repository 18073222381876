import { FreteCompletoDTO, FreteProdutoDTO } from "./FreteDTO"

export default class FreteHelper {


    public static verificarInformacoesFrete(freteCompleto: FreteCompletoDTO) {
        return (
            !['', 0, null, undefined, NaN].includes(freteCompleto.Frete.codigo_referencia) &&
            !['', 0, null, undefined, NaN].includes(freteCompleto.Frete.tipo_operacao) &&
            freteCompleto.Carrocerias.length !== 0 &&
            !['', 0, null, undefined, NaN].includes(freteCompleto.Frete.peso)
        )
    }

    public static verificarInformacoesLocalFrete(freteCompleto: FreteCompletoDTO) {
        return (
            !['', 0, null, undefined, NaN].includes(freteCompleto.Coleta.bairro) &&
            !['', 0, null, undefined, NaN].includes(freteCompleto.Coleta.endereco) &&
            !['', 0, null, undefined, NaN].includes(freteCompleto.Coleta.numero) &&
            !['', 0, null, undefined, NaN].includes(freteCompleto.Coleta.data_prevista) &&
            !['', 0, null, undefined, NaN].includes(freteCompleto.Coleta.municipio) &&
            !['', 0, null, undefined, NaN].includes(freteCompleto.Coleta.uf) &&

            !['', 0, null, undefined, NaN].includes(freteCompleto.Entrega.bairro) &&
            !['', 0, null, undefined, NaN].includes(freteCompleto.Entrega.endereco) &&
            !['', 0, null, undefined, NaN].includes(freteCompleto.Entrega.numero) &&
            !['', 0, null, undefined, NaN].includes(freteCompleto.Entrega.data_prevista) &&
            !['', 0, null, undefined, NaN].includes(freteCompleto.Entrega.municipio) &&
            !['', 0, null, undefined, NaN].includes(freteCompleto.Entrega.uf) &&

            !['', 0, null, undefined, NaN].includes(freteCompleto.Cliente.cnpj) &&
            !['', 0, null, undefined, NaN].includes(freteCompleto.Cliente.email) &&
            !['', 0, null, undefined, NaN].includes(freteCompleto.Cliente.nome) &&
            !['', 0, null, undefined, NaN].includes(freteCompleto.Cliente.telefone_celular)
        )
    }

    public static verificarProdutosFrete(produtos: FreteProdutoDTO[]) {
        return (produtos && produtos.length !== 0)
    }

    public static verificarInformacoesFinais(freteCompleto: FreteCompletoDTO) {
        return (
            ((freteCompleto.Frete.frete_motorista && !['', 0, null, undefined, NaN, 'R$ 0,00'].includes(freteCompleto.Frete.frete_motorista_valor_text)) || (freteCompleto.Frete.frete_transportadora && !['', 0, null, undefined, NaN, 'R$ 0,00'].includes(freteCompleto.Frete.frete_transportadora_valor_text)))
        )

    }
}