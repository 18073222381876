





















import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO"
import { NavegacaoContratacao, NavegacaoContratacaoTipoCadastro } from "@/domains/contratacao/ContratacaoEnum"
import { Vue, Component } from "vue-property-decorator"
import { mapGetters } from "vuex"

@Component({
  computed: {
    ...mapGetters({
      contratacao: "contratacaostate/contratacao",
    })
  }
})
export default class DesejaCadastrarFreteCotacao extends Vue {
  contratacao!: ContratacaoDTO
  navegacaoContratacao = NavegacaoContratacao
  tipoCadastro = NavegacaoContratacaoTipoCadastro

  nextPage() {
    this.$store.dispatch("contratacaostate/setStepContratacao", this.contratacao.step_contratacao + 1)
    this.$store.dispatch("contratacaostate/setMenuSelecionado", this.navegacaoContratacao.ContratacaoInformacoesAdicionais)
  }

  previousPage() {
    this.$store.dispatch("contratacaostate/setStepContratacao", this.contratacao.step_contratacao - 1)
    this.$store.dispatch("contratacaostate/setMenuSelecionado", this.navegacaoContratacao.ContratacaoDadosDaEmpresa)
  }

  goToCadastrarFrete() {
    this.$store.dispatch("contratacaostate/setStepContratacao", 1)
    this.$store.dispatch("contratacaostate/setTipoCadastro", this.tipoCadastro.frete)
    this.$store.dispatch("contratacaostate/setMenuSelecionado", this.navegacaoContratacao.FreteInformacoesIniciais)
  }

  goToCadastrarCotacao() {
    this.$store.dispatch("contratacaostate/setStepContratacao", 1)
    this.$store.dispatch("contratacaostate/setTipoCadastro", this.tipoCadastro.cotacao)
    this.$store.dispatch("contratacaostate/setMenuSelecionado", this.navegacaoContratacao.CotacaoInformacoesIniciais)
  }

}
