import { render, staticRenderFns } from "./InformacoesAdicionais.vue?vue&type=template&id=2ae9b5b5&scoped=true&"
import script from "./InformacoesAdicionais.vue?vue&type=script&lang=ts&"
export * from "./InformacoesAdicionais.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ae9b5b5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardTitle,VChip,VChipGroup,VCol,VCombobox,VProgressCircular,VRadio,VRadioGroup,VRow,VSelect,VTextField})
