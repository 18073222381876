import HttpProvider from "@/providers/HttpProvider"
import { AxiosResponse } from "axios"
import { EstadoDTO } from "./EstadoDTO"

class EstadoAPI {

    private httpProvider: HttpProvider

    constructor() {
        this.httpProvider = new HttpProvider()
    }

    public buscarEstados(): Promise<AxiosResponse<Array<EstadoDTO>>> {
        return this.httpProvider.axiosInstanceBase.get(`/estado/v2`)
    }

}


export default EstadoAPI
