






















































































































































































































































































































import ErrorHandling from "@/domains/application/ErrorHandling";
import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO";
import { NavegacaoContratacao } from "@/domains/contratacao/ContratacaoEnum";
import { ContaPagamento } from "@/domains/conta/ContaDTO";
import ContaAPI from "@/domains/conta/ContaAPI";
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import IuguAPI from "@/domains/iugu/IuguAPI";
import ContratacaoHelper from "@/domains/contratacao/ContratacaoHelper";
import { IuguCreditCard } from "@/domains/iugu/iugu";
import ContaBO from "@/domains/conta/ContaBO";
import ContaFactory from "@/domains/conta/ContaFactory";
import { ResponseCadastroConta } from "@/domains/conta/ContaDTO";
import CotacaoCompletaDTO from "@/domains/cotacao/CotacaoCompletaDTO";
import { FreteCompletoDTO } from "@/domains/frete/FreteDTO";
import LocalStorageProvider, {
  LocalStorageEnum,
} from "@/providers/LocalStorageProviders";
import ConfigDTO from "@/domains/application/ConfigDTO";
import { PlanosEnum } from "@/domains/planos/PlanosEnum";
import PlanosAPI from "@/domains/planos/PlanosAPI";

@Component({
  computed: {
    ...mapGetters({
      contratacao: "contratacaostate/contratacao",
      cotacaoCompleta: "cotacaostate/cotacaoCompleta",
      freteCompleto: "fretestate/freteCompleto",
    }),
  },
})
export default class Pagamento extends Vue {
  contratacao!: ContratacaoDTO;
  cotacaoCompleta!: CotacaoCompletaDTO;
  freteCompleto!: FreteCompletoDTO;

  navegacaoContratacao = NavegacaoContratacao;
  bandeiraDoCartao = "default";
  criouConta = false;
  responseConta!: ResponseCadastroConta;
  contratacaohelper = ContratacaoHelper;
  cadastrandoConta = false;
  valorPlano = 0;

  creditCard: IuguCreditCard = {
    cardNumber: "",
    name: "",
    dueMonth: "",
    dueYear: "",
    verificationValue: "",
    lastName: "",
  };

  mounted() {
    setTimeout(() => {
      this.contratacao.plano_selecionado = "GRATIS";
      this.contratacao.tipo = "TRANSPORTADORA";
      this.buscarPlanoSelecionado();
      this.calcularValorPlano();
    }, 500);
  }

  //se algum dia mudar o plano de 'grátis' ou o id, vai precisar arrumar aqui que está chumbado o ID do plano id_plano:
  async buscarPlanoSelecionado() {
    try {
      const response = await new PlanosAPI().searchPlanById(5);
      this.$store.dispatch(
        "contratacaostate/setplano_selecionado_completo",
        response.data
      );
    } catch (error) {
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true);
      this.$store.dispatch(
        "appstate/setSnackbarErrorMessage",
        ErrorHandling.tratarMensagemDeError(error)
      );
    }
  }

  async buscarBandeiraCartao() {
    if (!this.creditCard.cardNumber) return;
    const cardNumber = this.creditCard.cardNumber.trim();
    const response = await IuguAPI.getFlagCard(cardNumber);
    if (
      !this.bandeiraDoCartao ||
      [false, null, undefined, "", 0, NaN].includes(response)
    ) {
      this.bandeiraDoCartao = "default";
      return;
    }
    this.bandeiraDoCartao = response;
  }

  async gerarBoleto() {
    try {
      this.cadastrandoConta = true;
      ContaBO.validateAccount(this.contratacao);
      const conta = ContaFactory.buildAccount(this.contratacao);

      if (!this.criouConta) {
        const response = await new ContaAPI().createAccount(conta);
        this.responseConta = response.data;
      }

      this.criouConta = true;

      // const payment: ContaPagamento = {
      //   situacao_atual: this.responseConta.situacao_cadastro_inicial,
      //   id_conta: this.responseConta.id_conta,
      //   forma_pagamento: "Boleto",
      // };

      // const responsePagamentoBoleto = await new ContaAPI().firstPayOnHirePlan(
      //   payment
      // );

      // if (this.contratacao.plano_selecionado !== PlanosEnum.SPOT)
      //   window.open(`${responsePagamentoBoleto.data.pdf}`, "_blank");

      const config = LocalStorageProvider.retrieve<ConfigDTO>(
        LocalStorageEnum.CONFIG_KEY
      );
      this.cadastrandoConta = false;
      window.location.href = `${process.env.VUE_APP_API_BASE}/painel/#!/url-launcher?route=conta-tab-visao-geral-empresas&k=${config.jwt}&nomeE=${this.responseConta.nome_fantasia}&idC=${this.responseConta.id_conta}&type=${this.responseConta.tipo}`;
    } catch (error) {
      this.cadastrandoConta = false;
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true);
      this.$store.dispatch(
        "appstate/setSnackbarErrorMessage",
        ErrorHandling.tratarMensagemDeError(error)
      );
    }
  }

  async pagarComCartao() {
    try {
      this.cadastrandoConta = true;
      ContaBO.validateAccount(this.contratacao);
      ContaBO.validateCard(this.creditCard);
      const conta = ContaFactory.buildAccount(this.contratacao);

      const prepared = await IuguAPI.prepareCreditCard(this.creditCard);
      const iuguCreditCard = await IuguAPI.newIuguCreditCard(prepared);
      const token = await IuguAPI.newIuguCreditCardToken(iuguCreditCard);

      if (!this.criouConta) {
        const response = await new ContaAPI().createAccount(conta);
        this.responseConta = response.data;
      }

      this.criouConta = true;

      const payment: ContaPagamento = {
        situacao_atual: this.responseConta.situacao_cadastro_inicial,
        id_conta: this.responseConta.id_conta,
        forma_pagamento: "Cartão de crédito",
        token: token,
        descricao: "Cartão de crédito",
      };

      await new ContaAPI().firstPayOnHirePlan(payment);

      const config = LocalStorageProvider.retrieve<ConfigDTO>(
        LocalStorageEnum.CONFIG_KEY
      );
      this.cadastrandoConta = false;
      window.location.href = `${process.env.VUE_APP_API_BASE}/painel/#!/url-launcher?route=conta-tab-visao-geral-empresas&k=${config.jwt}&nomeE=${this.responseConta.nome_fantasia}&idC=${this.responseConta.id_conta}&type=${this.responseConta.tipo}`;
    } catch (error) {
      this.cadastrandoConta = false;
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true);
      this.$store.dispatch(
        "appstate/setSnackbarErrorMessage",
        ErrorHandling.tratarMensagemDeError(error)
      );
    }
  }

  async criarContaContaTrial() {
    this.cadastrandoConta = true;
    try {
      ContaBO.validateAccountTrial(
        this.contratacao,
        this.freteCompleto,
        this.cotacaoCompleta
      );
      const conta = ContaFactory.buildTrialAccount(
        this.contratacao,
        this.freteCompleto,
        this.cotacaoCompleta
      );

      if (!this.criouConta) {
        const response = await new ContaAPI().createTrialAccount(conta);
        this.responseConta = response.data;
      }

      this.criouConta = true;

      const config = LocalStorageProvider.retrieve<ConfigDTO>(
        LocalStorageEnum.CONFIG_KEY
      );
      this.cadastrandoConta = false;
      window.location.href = `${process.env.VUE_APP_API_BASE}/painel/#!/url-launcher?route=conta-tab-visao-geral-empresas&k=${config.jwt}&nomeE=${conta.solicitacao.nome_empresa}&idC=${this.responseConta.id_conta}&type=${this.responseConta.tipo}`;
    } catch (error) {
      this.cadastrandoConta = false;
      this.$store.dispatch("appstate/setSnackbarErrorVisibility", true);
      this.$store.dispatch(
        "appstate/setSnackbarErrorMessage",
        ErrorHandling.tratarMensagemDeError(error)
      );
    }
  }

  get termo_uso_aceito() {
    return this.contratacao.termo_uso_aceito;
  }

  set termo_uso_aceito(termo_uso_aceito: boolean) {
    this.$store.dispatch(
      "contratacaostate/setTermoUsoAceito",
      termo_uso_aceito
    );
  }

  calcularValorPlano() {
    this.valorPlano =
      this.contratacao.tipo === "TOMADOR"
        ? this.valoresCalculadosTomador()
        : this.contratacao.plano_selecionado_completo.pos_embarque_valor_modulo;
  }

  valoresCalculadosTomador() {
    const plano = this.contratacao.plano_selecionado_completo;

    const { gestao, cotacao, agendamento_embarques } =
      this.contratacao.modulos_selecionados;

    if (gestao && cotacao && agendamento_embarques) {
      return (
        plano.pos_embarque_valor_modulo +
        plano.agendamento_valor_modulo +
        plano.cotacao_valor_modulo
      );
    } else if (gestao && agendamento_embarques) {
      return plano.pos_embarque_valor_modulo + plano.agendamento_valor_modulo;
    } else if (cotacao && agendamento_embarques) {
      return plano.cotacao_valor_modulo + plano.agendamento_valor_modulo;
    } else if (cotacao && gestao) {
      return plano.cotacao_valor_modulo + plano.pos_embarque_valor_modulo;
    } else if (cotacao) {
      return plano.cotacao_valor_modulo;
    } else if (agendamento_embarques) {
      return plano.agendamento_valor_modulo;
    } else if (gestao) {
      return plano.pos_embarque_valor_modulo;
    } else {
      return 0;
    }
  }
}
