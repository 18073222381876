import BusinessError from "../application/BusinessError"
import BuscaTransportadoraDTO from "../transportadora/BuscaTransportadoraDTO"
import CotacaoCompletaDTO, { CotacaoProdutoDTO } from "./CotacaoCompletaDTO"

export default class CotacaoBO {
    public static validarInformacoesDoProduto(produto: CotacaoProdutoDTO) {
        if (['', 0, null, undefined, NaN].includes(produto.descricao_generica)) throw new BusinessError('O campo produto está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(produto.embalagem)) throw new BusinessError('O campo embalagem está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(produto.peso) || produto.peso > 1000000000 || produto.peso < 0) throw new BusinessError('O campo peso está com valor inválido')
    }

    public static validarInformacoesDaCotacao(cotacao: CotacaoCompletaDTO) {
        if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.tipo_cotacao)) throw new BusinessError('O campo tipo de cotação está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.tipo_precificacao)) throw new BusinessError('O campo tipo de precificação está com valor inválido')
        if ((cotacao.cotacao_frete.tipo_cotacao === 'BUSCA_TRANSPORTADORA' && cotacao.cotacao_frete.tipo_precificacao === 'VALOR_COMPOSTO' && cotacao.cotacao_frete.valor_habilitado === 'Sim') && ['', 0, null, undefined, NaN, 'R$ 0,00'].includes(cotacao.cotacao_frete.valor_text)) throw new BusinessError('O campo valor do frete está com valor inválido')
        if ((cotacao.cotacao_frete.tipo_cotacao === 'BUSCA_TRANSPORTADORA' && cotacao.cotacao_frete.tipo_precificacao === 'VALOR_COMPOSTO' && cotacao.cotacao_frete.vale_pedagio_habilitado === 'Sim') && ['', 0, null, undefined, NaN, 'R$ 0,00'].includes(cotacao.cotacao_frete.vale_pedagio_valor_text)) throw new BusinessError('O campo valor do vale padágio está com valor inválido')
        if ((cotacao.cotacao_frete.tipo_cotacao === 'BUSCA_TRANSPORTADORA' && cotacao.cotacao_frete.tipo_precificacao === 'VALOR_COMPOSTO' && cotacao.cotacao_frete.ad_valorem_habilitado === 'Sim') && ['', 0, null, undefined, NaN, '0,00 %'].includes(cotacao.cotacao_frete.ad_valorem_valor_text)) throw new BusinessError('O campo percentual do seguro está com valor inválido')
        if ((cotacao.cotacao_frete.tipo_cotacao === 'BUSCA_TRANSPORTADORA' && cotacao.cotacao_frete.tipo_precificacao === 'VALOR_COMPOSTO' && cotacao.cotacao_frete.estimativa_entrega_habilitado === 'Sim') && ['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.estimativa_entrega_dias)) throw new BusinessError('O campo dias está com valor inválido')
        if ((cotacao.cotacao_frete.tipo_cotacao === 'BUSCA_TRANSPORTADORA' && cotacao.cotacao_frete.tipo_precificacao === 'ALL_IN') && ['', 0, null, undefined, NaN, 'R$ 0,00'].includes(cotacao.cotacao_frete.valor_text)) throw new BusinessError('O campo valor do frete está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.codigo_referencia)) throw new BusinessError('O campo código de referência está com valor inválido')
        if (!cotacao.carrocerias || cotacao.carrocerias.length === 0) throw new BusinessError('É necessário escolher uma carroceria')
        if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.peso) || cotacao.cotacao_frete.peso > 1000000000 || cotacao.cotacao_frete.peso < 0) throw new BusinessError('O campo peso está com valor inválido')
    }

    public static validarInformacoesLocalDeColetaEntrega(cotacao: CotacaoCompletaDTO) {
        if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.coleta_uf)) throw new BusinessError('É necessário escolher um estado de coleta')
        if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.coleta_municipio)) throw new BusinessError('É necessário escolher um município de coleta')
        if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.entrega_uf)) throw new BusinessError('É necessário escolher um estado de entrega')
        if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.entrega_municipio)) throw new BusinessError('É necessário escolher um município de entrega')
    }

    public static validarInformacoesProdutos(produtos: CotacaoProdutoDTO[]) {
        if (!produtos || produtos.length === 0) throw new BusinessError('É necessário escolher ao menos um produto')
    }

    public static validarFinalizacaoDaCotacao(cotacao: CotacaoCompletaDTO, transportadoras: BuscaTransportadoraDTO[]) {
        if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.tipo_preco_frete)) throw new BusinessError('É necessário escolher um estado de coleta')


        if ((cotacao.cotacao_frete.tipo_cotacao === 'LEILAO' && cotacao.cotacao_frete.tipo_precificacao === 'VALOR_COMPOSTO' && cotacao.cotacao_frete.valor_habilitado === 'Sim') && ['', 0, null, undefined, NaN, 'R$ 0,00'].includes(cotacao.cotacao_frete.valor_text)) throw new BusinessError('O campo valor do frete está com valor inválido')
        if ((cotacao.cotacao_frete.tipo_cotacao === 'LEILAO' && cotacao.cotacao_frete.tipo_precificacao === 'VALOR_COMPOSTO' && cotacao.cotacao_frete.vale_pedagio_habilitado === 'Sim') && ['', 0, null, undefined, NaN, 'R$ 0,00'].includes(cotacao.cotacao_frete.vale_pedagio_valor_text)) throw new BusinessError('O campo valor do vale padágio está com valor inválido')
        if ((cotacao.cotacao_frete.tipo_cotacao === 'LEILAO' && cotacao.cotacao_frete.tipo_precificacao === 'VALOR_COMPOSTO' && cotacao.cotacao_frete.ad_valorem_habilitado === 'Sim') && ['', 0, null, undefined, NaN, '0,00 %'].includes(cotacao.cotacao_frete.ad_valorem_valor_text)) throw new BusinessError('O campo percentual do seguro está com valor inválido')
        if ((cotacao.cotacao_frete.tipo_cotacao === 'LEILAO' && cotacao.cotacao_frete.tipo_precificacao === 'VALOR_COMPOSTO' && cotacao.cotacao_frete.estimativa_entrega_habilitado === 'Sim') && ['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.estimativa_entrega_dias)) throw new BusinessError('O campo dias está com valor inválido')
        if ((cotacao.cotacao_frete.tipo_cotacao === 'LEILAO' && cotacao.cotacao_frete.tipo_precificacao === 'ALL_IN') && ['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.valor)) throw new BusinessError('O campo valor do frete está com valor inválido')

        if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete._data_inicio_cotacao)) throw new BusinessError('O campo data início cotação está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete._hora_inicio_cotacao)) throw new BusinessError('O campo hora início cotação está com valor inválido')
        // if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.data_inicio_cotacao)) throw new BusinessError('O campo data início cotação está com valor inválido')

        if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete._data_termino_cotacao)) throw new BusinessError('O campo data termino cotação está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete._hora_termino_cotacao)) throw new BusinessError('O campo hora termino cotação está com valor inválido')
        // if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.data_termino_cotacao)) throw new BusinessError('O campo data termino cotação está com valor inválido')

        if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.data_inicio_vigencia)) throw new BusinessError('O campo data início vigência está com valor inválido')
        if (['', 0, null, undefined, NaN].includes(cotacao.cotacao_frete.data_termino_vigencia)) throw new BusinessError('O campo data termino vigência está com valor inválido')

        if (!transportadoras || transportadoras.length === 0) throw new BusinessError('É necessário escolher uma transportadora')
        if (!cotacao.convites || cotacao.convites.length === 0) throw new BusinessError('É necessário escolher uma transportadora')

    }
}